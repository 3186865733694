import { createSelector, createStructuredSelector } from 'reselect'
import { toPairs } from 'ramda'
import { orderBy } from 'lodash'

export const devicesWithoutBatteries = ({ dashboard }) =>
  dashboard.getIn(['totalDevicesStatus'], {})

export const getLanguage = ({ user }) => user.getIn(['language'], []);

export const siteId = ({ dashboard }) => dashboard.getIn(['siteId'], '')

export const getSites = ({ siteManager }) => siteManager.getIn(['rootSitesById'], [])

export const siteIdDashboard = ({ dashboard }) => dashboard.getIn(['siteId'], '')

export const editing = ({ dashboard }) => dashboard.getIn(['editing'], false)

export const loggedIn = ({ login }) => login.getIn(['loggedIn'], false)

export const devices = ({ dashboard }) => dashboard.getIn(['typesOfDevicesCard', 'data'], [])

export const dashboardPreferences = ({ userSettings }) => userSettings.getIn(['selectedDashboard'], {})

export const batteryUsageSection = createSelector(
  [devicesWithoutBatteries],
  devices => devices.getIn(['total']) > 0
)

export const mobileComputerSection = createSelector(
  [devices],
  devicesByType => devicesByType
    .filter(devicetype => devicetype.key === 'mobilecomputer').length > 0
)

export const fixedPrintersSection = createSelector(
  [devices],
  devicesByType => devicesByType
    .filter(devicetype => devicetype.key === 'printer').length > 0
)

export const scannersSection = createSelector(
  [devices],
  devicesByType => devicesByType
    .filter(devicetype => devicetype.key === 'scanner' || devicetype.key === 'mobilecomputer').length > 0
)

export const sections = createSelector(
  [dashboardPreferences],
  dashboardPreferences => orderBy(toPairs(dashboardPreferences.getIn(['sections'], {})).map(([key, obj]) => obj), ['position'], ['asc'])
)

export const cards = createSelector(
  [dashboardPreferences],
  dashboardPreferences => dashboardPreferences.getIn(['cards'], {})
)

export const theme = ({ user }) => user.getIn(['theme'], {})

export const showDrill = ({ dashboard }) => dashboard.getIn(['drilldown', 'isOpen'], false)

export const dataKeyDetail = ({ dashboard }) => dashboard.getIn(['drilldown', 'dataKey'], '')

export default createStructuredSelector({
  siteId,
  siteIdDashboard,
  getSites,
  editing,
  loggedIn,
  batteryUsageSection,
  mobileComputerSection,
  fixedPrintersSection,
  scannersSection,
  theme,
  sections,
  cards,
  showDrill,
  dataKeyDetail,
  getLanguage
})
