import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import BreadcrumbActions from '../Redux/BreadcrumbRedux'
import config from '../Config/AppConfig'


export class EpReportPage extends Component {
  async redirectToEp(loggedIn, token) {
    if (loggedIn) {
      try {
        const childWindow = window.open('', '_blank');

        if(!childWindow){      
          return
        }
         // Open the EP app window after successful communication
         setTimeout(() => {
          if(childWindow){
            let url = `${config.api.epUrl}`;
            childWindow.location.href = url;           
          }
        },100)     

        const response = await fetch(`${config.api.epUrl}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',        
            'Authorization': `Bearer ${token}`,
          },
          
        });

          this.props.history.goBack();
        
      } catch (error) {
        console.error("Error during POST request:", error);
      }
    }
  }

  render() {
    const { token, loggedIn } = this.props;
    if(loggedIn){
      this.redirectToEp(loggedIn, token);
    }
    
    return <div />;
  }
}

EpReportPage.propTypes = {
  loggedIn: PropTypes.bool,
  token: PropTypes.string
}

EpReportPage.defaultProps = {
  loggedIn: false,
  token: ''
}

const mapDispatchToProps = (dispatch) => {
    return {
      setBreadcrumb: (items) => dispatch(BreadcrumbActions.breadcrumbSetItems(items))
    }
}

const mapStateToProps = state => {
  return {
    loggedIn: state.login.loggedIn,
    token: state.login.accessToken
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EpReportPage)
