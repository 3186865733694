import { schema } from 'normalizr'
import * as R from 'ramda'
import { Site, SiteType, Address, CreateSite } from './interfaces'

interface UglyOrganization {
  organizationalUnitIdentifier: OrganizationalUnitIdentifier
  organizationalUnitHierarchy: string
  contactInformation?: {
    addresses?: UglyAddress[]
  }
  association?: UglySite[]
}

interface OrganizationalUnitIdentifier {
  organizationalUnitGuid: string
  organizationalUnitName: string
}

interface UglySite {
  organizationalUnitIdentifier: OrganizationalUnitIdentifier
  organizationalUnitHierarchy: string
  parentIdentifier: OrganizationalUnitIdentifier
  contactInformation?: {
    addresses?: UglyAddress[]
  }
  association?: UglySite[]
  site?: UglyBuilding[]
}

interface SiteIdentifier {
  siteGuid: string
  siteName: string
}

interface UglyBuilding {
  siteIdentifier: SiteIdentifier
  referenceUnit?: OrganizationalUnitIdentifier[]
  contactInformation?: {
    addresses?: UglyAddress[]
  }
  zone: (UglyFloor | UglyZone)[]
}

interface ZoneIdentifier {
  zoneGuid: string
  zoneName: string
}

interface UglyFloor {
  zoneIdentifier: ZoneIdentifier & { zoneType: 'zone' }
  referenceZone: ZoneIdentifier & { zoneType: null }
  children: UglyZone[]
}

interface UglyZone {
  zoneIdentifier: ZoneIdentifier & { zoneType: 'subzone' }
  referenceZone: ZoneIdentifier & { zoneType: 'zone' }
  children?: UglyZone[]
}

interface UglyAddress {
  address1: string
  address2: string
  cityFullName: string
  stateFullName: string
  countryShortName: string
  areaPincode: string
}

type BackendSiteObject = UglyOrganization | UglySite | UglyBuilding | UglyFloor | UglyZone

type SiteWithChildren = Site & { children: BackendSiteObject[] }

const siteEntity = new schema.Entity('sites', {}, {
  idAttribute: (entity: BackendSiteObject) => {
    if (isBuilding(entity)) {
      return (entity as UglyBuilding).siteIdentifier?.siteGuid
    } else if (isFloor(entity)) {
      return (entity as UglyFloor).zoneIdentifier?.zoneGuid
    } else if (isZone(entity)) {
      return (entity as UglyZone).zoneIdentifier?.zoneGuid
    } else if (isSite(entity)) {
      return (entity as UglySite).organizationalUnitIdentifier?.organizationalUnitGuid
    } else if (isOrganization(entity)) {
      return (entity as UglyOrganization).organizationalUnitIdentifier?.organizationalUnitGuid
    }

    throw new Error('Could not find id attribute for site')
  },
  processStrategy: (entity: BackendSiteObject, parent: BackendSiteObject | SiteWithChildren) => {
    if (isOrganization(entity)) {
      return prettifyOrganization(entity as UglyOrganization)
    }

    if ('orgId' in parent) {
      if (isSite(entity)) {
        return prettifySite(parent.orgId, entity as UglySite)
      } else if (isBuilding(entity)) {
        return prettifyBuilding(parent.orgId, entity as UglyBuilding)
      } else if (isFloor(entity)) {
        return prettifyFloor(parent.orgId, entity as UglyFloor)
      } else if (isZone(entity)) {
        return prettifyZone(parent.orgId, entity as UglyZone)
      }
    } else if (isSite(entity)) {
      const site = entity as UglySite
      return prettifySite(site.parentIdentifier?.organizationalUnitGuid, site)
    } else if (isBuilding(entity)) {
      const building = entity as UglyBuilding
      return prettifyBuilding(building.siteIdentifier?.siteGuid, building)
    } else if (isFloor(entity)) {
      const floor = entity as UglyFloor
      return prettifyFloor(floor.zoneIdentifier?.zoneGuid, floor)
    } else if (isZone(entity)) {
      const zone = entity as UglyZone
      return prettifyZone(zone.zoneIdentifier?.zoneGuid, zone)
    }

    throw new Error('Unknown site type for site')
  }
})

// Recursive schema
siteEntity.define({ children: [siteEntity] })

function isOrganization (entity: BackendSiteObject) {
  if ('organizationalUnitIdentifier' in entity) {
    if (entity.organizationalUnitIdentifier.organizationalUnitName == null) return false
    if (!('parentIdentifier' in entity) || entity.parentIdentifier == null) return true
  }

  return false
}

function isSite (entity: BackendSiteObject) {
  if ('organizationalUnitIdentifier' in entity) {
    if (entity.organizationalUnitIdentifier.organizationalUnitName == null) return false
    if ('parentIdentifier' in entity && entity.parentIdentifier != null) return true
  }

  return false
}

function isBuilding (entity: BackendSiteObject) {
  return 'siteIdentifier' in entity && entity.siteIdentifier.siteGuid != null
}

function isFloor (entity: BackendSiteObject) {
  if (!('zoneIdentifier' in entity)) return false
  return entity.zoneIdentifier.zoneType?.toLowerCase() === 'zone'
}

function isZone (entity: BackendSiteObject) {
  if (!('zoneIdentifier' in entity)) return false
  return entity.zoneIdentifier.zoneType?.toLowerCase() === 'subzone'
}

/* All prettify functions return a format of
 * {
 *   id: string,
 *   parentId: string | undefined,
 *   type: string,
 *   name: string,
 *   children: array,
 *   address: {
 *     address1: string,
 *     address2: string | null,
 *     city: string,
 *     state: string,
 *     countryCode: string,
 *     postalCode: string
 *   } | undefined
 * }
 */

function prettifyOrganization (org: UglyOrganization): SiteWithChildren {
  const id = org.organizationalUnitIdentifier.organizationalUnitGuid
  const name = org.organizationalUnitIdentifier.organizationalUnitName
  const addresses = org.contactInformation?.addresses ?? []
  const hierarchy = org.organizationalUnitHierarchy
  let address: Address | undefined
  if (addresses.length > 0) {
    address = {
      address1: addresses[0].address1,
      address2: addresses[0].address2,
      city: addresses[0].cityFullName,
      state: addresses[0].stateFullName,
      countryCode: addresses[0].countryShortName,
      postalCode: addresses[0].areaPincode
    }
  }

  return {
    orgId: id,
    id,
    name,
    type: SiteType.ORGANIZATION,
    address,
    children: org.association || [],
    hierarchy
  }
}

function prettifySite (orgId: string, site: UglySite): SiteWithChildren {
  const id = site.organizationalUnitIdentifier.organizationalUnitGuid
  const parentId = site.parentIdentifier?.organizationalUnitGuid ?? undefined
  const name = site.organizationalUnitIdentifier.organizationalUnitName
  const hierarchy = site.organizationalUnitHierarchy
  const addresses = site.contactInformation?.addresses ?? []
  var isPackageCar = (site.organizationalUnitIdentifier.resourceType == SiteType.PACKAGE_CAR )
  
  let address: Address | undefined
  if (addresses.length > 0) {
    address = {
      address1: addresses[0].address1,
      address2: addresses[0].address2,
      city: addresses[0].cityFullName,
      state: addresses[0].stateFullName,
      countryCode: addresses[0].countryShortName,
      postalCode: addresses[0].areaPincode
    }
  }
  
  return {
    orgId,
    id,
    parentId,
    name,
    type: (isPackageCar ? SiteType.PACKAGE_CAR : SiteType.SITE),
    address,
    children: [...(site.association || []), ...(site.site || [])],
    hierarchy
  }
}

function uglifySite (site: Site | CreateSite) {
  return {
    organizationalUnitIdentifier: {
      organizationalUnitGuid: 'id' in site ? site.id : undefined,
      organizationalUnitName: site.name
    },
    contactInformation: site.address != null
      ? {
        addresses: [{
          address1: site.address.address1,
          address2: site.address.address2,
          cityFullName: site.address.city,
          stateFullName: site.address.state,
          countryShortName: site.address.countryCode,
          areaPincode: site.address.postalCode
        }]
      }
      : undefined,
    parentIdentifier: site.parentId != null
      ? { organizationalUnitGuid: site.parentId }
      : undefined
  }
}

function prettifyBuilding (orgId: string, building: UglyBuilding): SiteWithChildren {
  const id = building.siteIdentifier.siteGuid
  const parentId = building.referenceUnit && building.referenceUnit.length > 0
    ? building.referenceUnit[0].organizationalUnitGuid
    : undefined
  const name = building.siteIdentifier.siteName

  const [floors, zones] = R.partition(
    floorOrZone => isFloor(floorOrZone),
    building.zone
  )

  const children = (floors as UglyFloor[]).map((floor) => ({ ...floor, children: (zones as UglyZone[]) }))

  const addresses = building.contactInformation?.addresses || []
  let address: Address | undefined
  if (addresses.length > 0) {
    address = {
      address1: addresses[0].address1,
      address2: addresses[0].address2,
      city: addresses[0].cityFullName,
      state: addresses[0].stateFullName,
      countryCode: addresses[0].countryShortName,
      postalCode: addresses[0].areaPincode
    }
  }

  return {
    orgId,
    id,
    parentId,
    name,
    type: SiteType.BUILDING,
    address,
    children
  }
}

function uglifyBuilding (building: Site | CreateSite) {
  return {
    siteIdentifier: {
      siteGuid: 'id' in building ? building.id : undefined,
      siteName: building.name
    },
    contactInformation: building.address != null
      ? {
        addresses: [{
          address1: building.address.address1,
          address2: building.address.address2,
          cityFullName: building.address.city,
          stateFullName: building.address.state,
          countryShortName: building.address.countryCode,
          areaPincode: building.address.postalCode
        }]
      }
      : undefined,
    referenceUnit: building.parentId != null
      ? [{ organizationalUnitGuid: building.parentId }]
      : undefined
  }
}

function prettifyFloor (orgId: string, floor: UglyFloor): SiteWithChildren {
  const id = floor.zoneIdentifier.zoneGuid
  const parentId = floor.referenceZone?.zoneGuid ?? undefined
  const name = floor.zoneIdentifier.zoneName

  const [zones, allDescendants] = R.partition(
    (subZone) => subZone.referenceZone?.zoneGuid === id,
    floor.children || []
  )

  const children = zones.map(zone => ({ ...zone, children: allDescendants }))

  return {
    orgId,
    id,
    parentId,
    type: SiteType.FLOOR,
    name,
    children
  }
}

function uglifyFloor (floor: Site | CreateSite) {
  return {
    zoneIdentifier: {
      zoneGuid: 'id' in floor ? floor.id : undefined,
      zoneName: floor.name,
      zoneType: 'zone'
    },
    referenceSite: floor.parentId != null
      ? { siteGuid: floor.parentId }
      : undefined
  }
}

function prettifyZone (orgId: string, zone: UglyZone): SiteWithChildren {
  const id = zone.zoneIdentifier.zoneGuid
  const parentId = zone.referenceZone?.zoneGuid ?? undefined
  const name = zone.zoneIdentifier.zoneName

  const [subZones, allDescendants] = R.partition(
    subZone => subZone.referenceZone?.zoneGuid === id,
    zone.children || []
  )

  const children = subZones.map(subZone => ({ ...subZone, children: allDescendants }))

  return {
    orgId,
    id,
    parentId,
    type: SiteType.ZONE,
    name,
    children
  }
}

function uglifyZone (zone: Site | CreateSite) {
  return {
    zoneIdentifier: {
      zoneGuid: 'id' in zone ? zone.id : undefined,
      zoneName: zone.name,
      zoneType: 'subzone'
    },
    referenceZone: zone.parentId != null
      ? { zoneGuid: zone.parentId }
      : undefined
  }
}

export {
  uglifySite,
  uglifyBuilding,
  uglifyFloor,
  uglifyZone,
  siteEntity
}
