import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { isValidJsonString } from '../Utils/PropertyHelper';

const { localStorage } = global;
const OP_INTEL_KEY = 'opintel-dashboard-siteid';

const { Types, Creators } = createActions({
  dashboardRequestAvgBatteryAgeCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseAvgBatteryAgeCard: ['data'],
  dashboardRequestDeviceUsageLevel: ['siteId', 'initialDate', 'finalDate'],
  dashboardDockingTimeDeviceUsageLevel: ['dockingTime'],
  dashboardResponseDeviceUsageLevel: ['data'],
  dashboardRequestPrinterUsageLevelByMeters: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponsePrinterUsageLevelByMeters: ['data'],
  dashboardRequestMobilePrinterUsageLevelByMeters: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseMobilePrinterUsageLevelByMeters: ['data'],
  dashboardRequestEventsForPrinters: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseEventsForPrinters: ['data'],
  dashboardRequestAvgBatteryCycleCard: ['siteId', 'initialDate', 'finalDate', 'cycleCountAbove'],
  dashboardResponseAvgBatteryCycleCard: ['data'],
  dashboardRequestScanPullsCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseScanPullsCard: ['data'],
  dashboardRequestPrintersOutOfPaperCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponsePrintersOutOfPaperCard: ['data'],
  dashboardRequestPrintersOutOfRibbonCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponsePrintersOutOfRibbonCard: ['data'],
  dashboardRequestPtcDevicesUse: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponsePtcDevicesUse: ['data'],
  dashboardRequestBatterySwaps: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseBatterySwaps: ['data'],
  dashboardSetAboveBatteryCycle: ['cycleCountAbove'],
  dashboardGetAboveBatteryCycle: ['cycleCountAbove'],
  dashboardRequestHardRebootsCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseHardRebootsCard: ['data'],
  dashboardSetSiteId: ['user', 'siteId'],
  dashboardCleanSiteId: [],
  dashboardRequestAlerts: ['siteId', 'pageNumber', 'pageSize', 'status'],
  dashboardSuccessAlerts: ['data'],
  dashboardFailureAlerts: null,
  dashboardRequestPrinterUsageLevelByLabels: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponsePrinterUsageLevelByLabels: ['data'],
  dashboardRequestMobilePrinterUsageLevelByLabels: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseMobilePrinterUsageLevelByLabels: ['data'],
  dashboardRequestAgingBatteriesCard: ['siteId', 'initialDate', 'finalDate', 'agingMonths'],
  dashboardResponseAgingBatteriesCard: ['data'],
  dashboardSetAgingBatteriesCard: ['agingMonths'],
  dashboardRequestBatteriesLowHealthCard: ['siteId', 'initialDate', 'finalDate', 'batteryPercentage'],
  dashboardResponseBatteriesLowHealthCard: ['data'],
  dashboardSetBatteriesLowHealthCard: ['batteryPercentage'],
  dashboardRequestDistributionOfDipsCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseDistributionOfDipsCard: ['data'],
  dashboardRequestTotalDevicesCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseTotalDevicesCard: ['data'],
  dashboardRequestTotalDevicesWithBatteriesCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseTotalDevicesWithBatteriesCard: ['data'],
  dashboardRequestTotalDevicesWithoutBatteriesCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseTotalDevicesWithoutBatteriesCard: ['data'],
  dashboardRequestTotalBatteriesCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseTotalBatteriesCard: ['data'],
  dashboardRequestFixedDevicesOfflineCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseFixedDevicesOfflineCard: ['data'],
  dashboardRequestBatteriesCycleAboveCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseBatteriesCycleAboveCard: ['data'],
  dashboardRequestBatteriesHealthBelowCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseBatteriesHealthBelowCard: ['data'],
  dashboardRequestBatteriesHealthBetweenCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseBatteriesHealthBetweenCard: ['data'],
  dashboardRequestFirmwareCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseFirmwareCard: ['data'],
  dashboardRequestScannerFirmwareCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseScannerFirmwareCard: ['data'],
  dashboardRequestOsCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseOsCard: ['data'],
  dashboardDroppedDevicesCardRequest: ['siteId', 'initialDate', 'finalDate'],
  dashboardDroppedDevicesCardSuccess: ['data', 'statusCode'],
  dashboardDroppedDevicesCardFailure: ['statusCode'],
  dashboardToggleLayoutEdit: null,
  dashboardGenericRequest: ['reqType', 'extraParam', 'paramDefaultValue'],
  dashboardGenericSuccess: ['reqType', 'data'],
  dashboardGenericFailure: ['reqType'],
  dashboardRequestTypesOfDevicesCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseTypesOfDevicesCard: ['data'],
  dashboardRequestTypesOfModelsCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseTypesOfModelsCard: ['data'],
  dashboardResponseBatteriesLowHealthEventsCard: ['data'],
  dashboardRequestBatteriesLowHealthEventsCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseUndockedChargeCard: ['data'],
  dashboardRequestUndockedChargeCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardRequestTotalDevicesStatus: ['siteId', 'initialDate', 'finalDate', 'hours'],
  dashboardResponseTotalDevicesStatus: ['data'],
  dashboardResponseScanVolume: ['data'],
  dashboardRequestScanVolume: ['siteId', 'initialDate', 'finalDate'],
  dashboardRequestPrintVolumeCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponsePrintVolumeCard: ['data'],
  dashboardRequestPrintersFaultyDots: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponsePrintersFaultyDots: ['data'],
  dashboardRequestHardUseDevicesCard: ['siteId', 'initialDate', 'finalDate', 'hoursOfUsePerDay'],
  dashboardResponseHardUseDevicesCard: ['data'],
  dashboardSetHardUseDevicesCard: ['hoursOfUsePerDay'],
  dashboardRequestHoursOfUseCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseHoursOfUseCard: ['data'],
  dashboardResponseScanVolumeByDevice: ['data'],
  dashboardRequestScanVolumeByDevice: ['siteId', 'initialDate', 'finalDate', 'serialNumber'],
  dashboardResponsePrintVolumeByDevice: ['data'],
  dashboardRequestPrintVolumeByDevice: ['siteId', 'initialDate', 'finalDate', 'serialNumber'],
  dashboardRequestSecurityPatch: ['siteId', 'initialDate', 'finalDate', 'deviceType'],
  dashboardResponseSecurityPatch: ['data'],
  dashboardRequestHardRebootsByDevice: ['siteId', 'initialDate', 'finalDate', 'serialNumber'],
  dashboardResponseHardRebootsByDevice: ['data'],
  dashboardRequestAppData: ['id'],
  dashboardResponseAppData: ['data'],
  dashboardRequestDeepSleepData: ['id', 'siteId'],
  dashboardResponseDeepSleepData: ['data'],
  dashboardRequestErrorsByDevice: ['id', 'siteId'],
  dashboardResponseErrorsByDevice: ['data'],
  dashboardRequestInventoryCyclesByDevice: ['id'],
  dashboardResponseInventoryCyclesByDevice: ['data'],
  dashboardRequestAppDataCustom: ['id', 'startDate', 'endDate'],
  dashboardResponseAppDataCustom: ['data'],
  dashboardRequestUsagePerDayByDevice: ['siteId', 'initialDate', 'finalDate', 'serialNumber'],
  dashboardResponseUsagePerDayByDevice: ['data'],
  dashboardRequestPrintedMetersByDevice: ['siteId', 'initialDate', 'finalDate', 'serialNumber'],
  dashboardResponsePrintedMetersByDevice: ['data'],
  dashboardRequestDroppedDevicesByDevice: ['siteId', 'initialDate', 'finalDate', 'serialNumber'],
  dashboardResponseDroppedDevicesByDevice: ['data'],
  dashboardRequestNewBatteriesLastMonthCard: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseNewBatteriesLastMonthCard: ['data'],
  dashboardSectionClick: ['index', 'collapsed'],
  dashboardOpenDrilldown: ['key', 'title', 'drillDownOptions'],
  dashboardUpdateDrilldown: ['key'],
  dashboardCloseDrilldown: null,
  dashboardRequestTotalBatteriesDetails: ['siteId', 'params'],
  dashboardResetDataTotalBatteries: null,
  dashboardResponseTotalBatteriesDetails: ['data'],
  dashboardRequestTotalBatteriesDetailsNextPage: ['siteId', 'params'],
  dashboardResponseTotalBatteriesDetailsNextPage: ['data'],
  dashboardRequestNotReportingBatteries: ['siteId', 'params'],
  dashboardResetDataNotReportingBatteries: null,
  dashboardResponseNotReportingBatteries: ['data'],
  dashboardRequestNotReportingBatteriesNextPage: ['siteId', 'params'],
  dashboardResponseNotReportingBatteriesNextPage: ['data'],
  dashboardRequestNotReportingBatteriesCard: ['siteId', 'params'],
  dashboardResponseNotReportingBatteriesCard: ['data'],
  dashboardRequestDeleteBatteries: ['organizationId', 'batteryList'],
  dashboardRequestDeleteBatteriesNotReporting: ['organizationId', 'batteryList'],
  dashboardResponseDeleteBatteries: ['batteryList'],
  dashboardResponseDeleteBatteriesNotReporting: ['batteryList'],
  dashboardRequestAddBatteries: ['organizationId', 'batteryList'],
  dashboardResponseAddBatteries: ['batteryList'],
  dashboardRequestDeletedBatteries: ['organizationId', 'params'],
  dashboardResetDataDeletedBatteries: null,
  dashboardResponseDeletedBatteries: ['data'],
  dashboardRequestDeletedBatteriesNextPage: ['organizationId', 'params'],
  dashboardResponseDeletedBatteriesNextPage: ['data'],
  dashboardRequestDeletedBatteriesCard: ['organizationId', 'params'],
  dashboardResponseDeletedBatteriesCard: ['data'],
  dashboardRequestApplicationUsageCard: ['siteId', 'params'],
  dashboardResponseApplicationUsageCard: ['data'],
  dashboardRequestNetworkIOCard: ['siteId', 'params'],
  dashboardResponseNetworkIOCard: ['data'],
  dashboardRequestApplicationUsageDetails: ['name', 'siteId', 'params'],
  dashboardRequestApplicationUsageDetailsNextPage: ['name', 'siteId', 'params'],
  dashboardResponseApplicationUsageDetailsNextPage: ['name', 'siteId', 'params'],
  dashboardResponseApplicationUsageDetails: ['data'],
  dashboardRequestApplicationUsageCardNextPage: ['siteId', 'params'],
  dashboardResponseApplicationUsageCardNextPage: ['data'],
  dashboardRequestNetworkIONextPage: ['siteId', 'params'],
  dashboardResponseNetworkIONextPage: ['data'],
  dashboardDockingTimeHardUseDevicesCard: ['dockingTime'],
  dashboardRequestHardUseDevicesDetails: ['siteId', 'initialDate', 'finalDate', 'details'],
  dashboardResponseHardUseDevicesDetails: ['data'],
  dashboardGenericResponseHardUseDevicesDetails: null,
  dashboardResponseLostAndFound: ['data'],
  dashboardRequestLostAndFound: ['siteId', 'initialDate', 'finalDate'],
  dashboardResponseLostAndFoundDetails: ['data'],
  dashboardResetDataLostAndFoundDetails: null,
  dashboardRequestLostAndFoundDetails: ['siteId', 'params'],
  dashboardResponseApplicationDisruptions: ['data'],
  dashboardRequestApplicationDisruptions: ['siteId', 'initialDate', 'finalDate'],
  dashboardResetDataApplicationDisruptionsDetails: null,
  dashboardRequestApplicationDisruptionsDetails: ['siteId', 'params'],
  dashboardResponseApplicationDisruptionsDetails: ['data'],
  dashboardRequestApplicationDisruptionsDetailsNextPage: ['siteId', 'params'],
  dashboardResponseApplicationDisruptionsDetailsNextPage: ['data'],
  dashboardResponseApplicationDisruptionsByDevice: ['data'],
  dashboardRequestApplicationDisruptionsByDevice: ['siteId', 'initialDate', 'finalDate', 'serialNumber'],
  dashboardResponseApplicationDisruptionsByApp: ['data'],
  dashboardRequestApplicationDisruptionsByApp: ['siteId', 'initialDate', 'finalDate'],
  dashboardRequestApplicationDisruptionsByAppDetails: ['siteId', 'params'],
  dashboardResponseApplicationDisruptionsByAppDetails: ['data'],
  dashboardRequestApplicationDisruptionsByAppDetailsNextPage: ['siteId', 'params'],
  dashboardResponseApplicationDisruptionsByAppDetailsNextPage: ['data'],
  dashboardResetDataApplicationDisruptionsByAppDetails: null,
});

export const DashboardTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
  avgBatteryHealthCard: { loading: false },
  avgBatteryAgeCard: { loading: false },
  deviceUsageLevels: { loading: false },
  printerUsageLevels: { loading: false },
  printerUsageLevelsByLabels: { loading: false },
  mobilePrinterUsageLevelsByLabels: { loading: false },
  mobilePrinterUsageLevelsByMeters: { loading: false },
  scanPullsCard: { loading: false, monthlyGrowth: 0 },
  printersOutOfPaperCard: { loading: false },
  printersOutOfRibbonCard: { loading: false },
  avgBatteryCycleCard: { loading: false },
  ptcDevicesUseCard: { loading: false },
  hardRebootsCard: { loading: false },
  batterySwapCard: { loading: false },
  agingBatteriesCard: { loading: false },
  chargeOvernightCard: { loading: false },
  batteriesLowHealthCard: { loading: false },
  distributionOfDipsCard: { loading: false },
  totalDevicesCard: { loading: false },
  totalDevicesWithBatteriesCard: { loading: false },
  totalDevicesWithoutBatteriesCard: { loading: false },
  totalBatteriesCard: { loading: false },
  fixedDevicesOfflineCard: { loading: false },
  batteryAbove: { loading: false },
  batteryHealthBelow: { loading: false },
  batteryHealthBetween: { loading: false },
  batteryHealthEvents: { loading: false },
  typesOfDevicesCard: { loading: false },
  typesOfModelsCard: { loading: false },
  distributionOfFirmwareCard: { loading: false },
  distributionOfOsCard: { loading: false },
  chargeWhenDockedCard: { loading: false },
  chargeWhenUndockedCard: { loading: false },
  droppedDevicesCard: { loading: false },
  scannerFirmwareCard: { loading: false },
  hardUseDevicesCard: { loading: false },
  hoursOfUseCard: { loading: false },
  cycleCountAbove: 500,
  agingMonths: 30,
  batteryPercentage: 75,
  hoursOfUsePerDay: 8,
  hoursOffline: 72,
  notReportingDays: 30,
  sections: [true, true, true, true, true, true],
  siteId: '',
  loading: false,
  alerts: [],
  editing: false,
  error: false,
  batteryaverage: { loading: false },
  batterylowhealthbatteryPercentage30: { loading: false },
  batterycyclecount: { loading: false },
  batterycyclecountcycleCount500: { loading: false },
  batteryagingagingMonths30: { loading: false },
  totalDevicesStatus: { loading: false },
  printersFaultyDots: { loading: false },
  scanVolume: { loading: false },
  printVolumeCard: { loading: false },
  scanVolumeByDevice: { loading: false },
  printVolumeByDeviceCard: { loading: false },
  devicesSecurityPatch: { loading: false },
  hardRebootsByDevice: { loading: false },
  errorsByDevice: { loading: false },
  inventoryCyclesByDevice: { loading: false },
  appData: { loading: false },
  appDataCustom: { loading: false },
  deepSleeps: { loading: false },
  usagePerDayByDevice: { loading: false, monthlyGrowth: 0 },
  printedMetersByDevice: { loading: false },
  droppedDevicesCardByDevice: { loading: false },
  newBatteriesLastMonth: { loading: false },
  levelOfCharge: { loading: false },
  devicesStatus: { loading: false },
  assetsReports: { loading: false },
  notReportingBatteriesCard: { loading: false },
  deletedBatteriesCard: { loading: false },
  drilldown: {
    data: [],
    dateKey: null,
    isOpen: false,
    title: '',
    drillDownOptions: {},
  },
  applicationUsage: { loading: false, days: 0 },
  networkIO: { loading: false, days: 0 },
  appUsageDetails: { loading: false },
  lostAndFound: {loading: false},
  applicationDisruptions: {loading: false},
  applicationDisruptionsDetails: { loading: false },
  applicationDisruptionsByDevice: {loading: false},
  applicationDisruptionsByApp: { loading: false },
});

/* ------------- Reducers ------------- */
export const dashboardRequestAvgBatteryAgeCard = (state) => state.merge({ avgBatteryAgeCard: { loading: true } });
export const dashboardResponseAvgBatteryAgeCard = (state, { data }) =>
  state.merge({ avgBatteryAgeCard: { ...(data || {}), loading: false } });

export const dashboardRequestDeviceUsageLevel = (state) => state.merge({ deviceUsageLevels: { loading: true } });
export const dashboardDockingTimeDeviceUsageLevel = (state, { dockingTime }) =>
  state.merge({ deviceUsageLevels: { ...state['deviceUsageLevels'], dockingTime: dockingTime } });
export const dashboardResponseDeviceUsageLevel = (state, { data }) =>
  state.merge({ deviceUsageLevels: { ...(data || {}), loading: false } });

export const dashboardRequestPrinterUsageLevelByMeters = (state) =>
  state.merge({ printerUsageLevels: { loading: true } });
export const dashboardResponsePrinterUsageLevelByMeters = (state, { data }) =>
  state.merge({ printerUsageLevels: { ...(data || {}), loading: false } });

export const dashboardRequestMobilePrinterUsageLevelByMeters = (state) =>
  state.merge({ mobilePrinterUsageLevelsByMeters: { loading: true } });
export const dashboardResponseMobilePrinterUsageLevelByMeters = (state, { data }) =>
  state.merge({ mobilePrinterUsageLevelsByMeters: { ...(data || {}), loading: false } });

export const dashboardRequestAvgBatteryCycleCard = (state, { cycleCountAbove }) =>
  state.merge({ avgBatteryCycleCard: { loading: true }, cycleCountAbove: cycleCountAbove || 500 });
export const dashboardResponseAvgBatteryCycleCard = (state, { data }) =>
  state.merge({ avgBatteryCycleCard: { ...(data || {}), loading: false } });

export const dashboardRequestScanPullsCard = (state) => state.merge({ scanPullsCard: { loading: true } });
export const dashboardResponseScanPullsCard = (state, { data }) =>
  state.merge({ scanPullsCard: { ...(data || {}), loading: false } });

export const dashboardRequestPrintersOutOfPaperCard = (state) =>
  state.merge({ printersOutOfPaperCard: { loading: true } });
export const dashboardResponsePrintersOutOfPaperCard = (state, { data }) =>
  state.merge({ printersOutOfPaperCard: { ...(data || {}), loading: false } });

export const dashboardRequestPrintersOutOfRibbonCard = (state) =>
  state.merge({ printersOutOfRibbonCard: { loading: true } });
export const dashboardResponsePrintersOutOfRibbonCard = (state, { data }) =>
  state.merge({ printersOutOfRibbonCard: { ...(data || {}), loading: false } });

export const dashboardRequestEventsForPrinters = (state) =>
  state.merge({ printersOutOfRibbonCard: { loading: true }, printersOutOfPaperCard: { loading: true } });
export const dashboardResponseEventsForPrinters = (state, { data }) => {
  return state.merge({
    printersOutOfRibbonCard: { ...data.printersOutOfRibbonCard, loading: false },
    printersOutOfPaperCard: { ...data.printersOutOfPaperCard, loading: false },
  });
};

export const dashboardRequestPtcDevicesUse = (state) => state.merge({ ptcDevicesUseCard: { loading: true } });
export const dashboardResponsePtcDevicesUse = (state, { data }) =>
  state.merge({ ptcDevicesUseCard: { ...(data || {}), loading: false } });

export const dashboardSetAboveBatteryCycle = (state, { cycleCountAbove }) => state.merge({ cycleCountAbove });
export const dashboardGetAboveBatteryCycle = (state, { cycleCountAbove }) =>
  state.merge({ avgBatteryCycleCard: cycleCountAbove || {} });

export const dashboardRequestHardRebootsCard = (state) => state.merge({ hardRebootsCard: { loading: true } });
export const dashboardResponseHardRebootsCard = (state, { data }) =>
  state.merge({ hardRebootsCard: { ...(data || {}), loading: false } });

export const dashboardRequestBatterySwaps = (state) => state.merge({ batterySwapCard: { loading: true } });
export const dashboardResponseBatterySwaps = (state, { data }) =>
  state.merge({ batterySwapCard: { ...(data || {}), loading: false } });

export const dashboardSetSiteId = (state, { user, siteId }) => {
  let storage;
  //  invalidate previous data structure
  if (isValidJsonString(localStorage.getItem(OP_INTEL_KEY))) {
    storage = JSON.parse(localStorage.getItem(OP_INTEL_KEY)) || {};
  } else {
    storage = {};
  }

  storage[user] = siteId;
  localStorage.setItem(OP_INTEL_KEY, JSON.stringify(storage));
  return state.merge({ siteId });
};

export const dashboardCleanSiteId = (state) => state.merge({ siteId: '' });

export const dashboardRequestAlerts = (state) => state.merge({ loading: true, alerts: [] });
export const dashboardFailureAlerts = (state) => state.merge({ loading: false });
export const dashboardSuccessAlerts = (state, { data }) => state.merge({ alerts: data, loading: false });

export const dashboardRequestPrinterUsageLevelByLabels = (state) =>
  state.merge({ printerUsageLevelsByLabels: { loading: true } });
export const dashboardResponsePrinterUsageLevelByLabels = (state, { data }) =>
  state.merge({ printerUsageLevelsByLabels: { ...(data || {}), loading: false } });

export const dashboardRequestMobilePrinterUsageLevelByLabels = (state) =>
  state.merge({ mobilePrinterUsageLevelsByLabels: { loading: true } });
export const dashboardResponseMobilePrinterUsageLevelByLabels = (state, { data }) =>
  state.merge({ mobilePrinterUsageLevelsByLabels: { ...(data || {}), loading: false } });

export const dashboardRequestAgingBatteriesCard = (state, { agingMonths }) =>
  state.merge({ agingBatteriesCard: { loading: true }, agingMonths: agingMonths || 30 });
export const dashboardResponseAgingBatteriesCard = (state, { data }) =>
  state.merge({ agingBatteriesCard: { ...(data || {}), loading: false } });

export const dashboardSetAgingBatteriesCard = (state, { agingMonths }) => state.merge({ agingMonths });

export const dashboardRequestBatteriesLowHealthCard = (state, { batteryPercentage }) =>
  state.merge({ batteriesLowHealthCard: { loading: true }, batteryPercentage: batteryPercentage || 75 });
export const dashboardResponseBatteriesLowHealthCard = (state, { data }) =>
  state.merge({ batteriesLowHealthCard: { ...(data || {}), loading: false } });

export const dashboardSetBatteriesLowHealthCard = (state, { batteryPercentage }) => state.merge({ batteryPercentage });

export const dashboardRequestDistributionOfDipsCard = (state) =>
  state.merge({ distributionOfDipsCard: { loading: true } });
export const dashboardResponseDistributionOfDipsCard = (state, { data }) =>
  state.merge({ distributionOfDipsCard: { ...(data || {}), loading: false } });

export const dashboardRequestTotalDevicesCard = (state) => state.merge({ totalDevicesCard: { loading: true } });
export const dashboardResponseTotalDevicesCard = (state, { data }) =>
  state.merge({ totalDevicesCard: { ...(data || {}), loading: false } });

export const dashboardRequestTotalDevicesWithBatteriesCard = (state) =>
  state.merge({
    totalDevicesWithBatteriesCard: { loading: true },
  });
export const dashboardResponseTotalDevicesWithBatteriesCard = (state, { data }) =>
  state.merge({
    totalDevicesWithBatteriesCard: data || [],
  });

export const dashboardRequestTotalDevicesWithoutBatteriesCard = (state) =>
  state.merge({
    totalDevicesWithoutBatteriesCard: { loading: true },
  });
export const dashboardResponseTotalDevicesWithoutBatteriesCard = (state, { data }) =>
  state.merge({
    totalDevicesWithoutBatteriesCard: data || [],
  });

export const dashboardRequestTotalBatteriesCard = (state) =>
  state.updateIn(['totalBatteriesCard', 'loading'], () => true);

export const dashboardResetDataTotalBatteries = (state) => {
  return state.updateIn(['totalBatteriesCard'], (value) => ({
    ...value,
    data: []
  }));
}

export const dashboardResponseTotalBatteriesCard = (state, { data }) =>
  state.updateIn(['totalBatteriesCard'], (value) => ({
    outputs: data.outputs,
    data: value.data || [],
    total: 0,
    totalBatteriesDashboard: data.total,
    statusCode: data.statusCode,
    loading: false,
  }));

export const dashboardRequestPrintersFaultyDots = (state) => state.merge({ printersFaultyDots: { loading: true } });

export const dashboardResponsePrintersFaultyDots = (state, { data }) =>
  state.merge({ printersFaultyDots: { ...(data || []), loading: false } });

export const dashboardRequestFixedDevicesOfflineCard = (state) =>
  state.merge({ fixedDevicesOfflineCard: { loading: true } });
export const dashboardResponseFixedDevicesOfflineCard = (state, { data }) =>
  state.merge({ fixedDevicesOfflineCard: { ...(data || {}), loading: false } });

export const dashboardRequestBatteriesCycleAboveCard = (state) => state.merge({ batteryAbove: { loading: true } });
export const dashboardResponseBatteriesCycleAboveCard = (state, { data }) =>
  state.merge({ batteryAbove: { ...(data || {}), loading: false } });

export const dashboardRequestBatteriesHealthBelowCard = (state) =>
  state.merge({ batteryHealthBelow: { loading: true } });
export const dashboardResponseBatteriesHealthBelowCard = (state, { data }) =>
  state.merge({ batteryHealthBelow: { ...(data || {}), loading: false } });

export const dashboardRequestBatteriesHealthBetweenCard = (state) =>
  state.merge({ batteryHealthBetween: { loading: true } });
export const dashboardResponseBatteriesHealthBetweenCard = (state, { data }) =>
  state.merge({ batteryHealthBetween: { ...(data || {}), loading: false } });

export const dashboardRequestTypesOfDevicesCard = (state) => state.merge({ typesOfDevicesCard: { loading: true } });
export const dashboardResponseTypesOfDevicesCard = (state, { data }) =>
  state.merge({ typesOfDevicesCard: { ...(data || {}), loading: false } });

export const dashboardRequestTypesOfModelsCard = (state) => state.merge({ typesOfModelsCard: { loading: true } });
export const dashboardResponseTypesOfModelsCard = (state, { data }) =>
  state.merge({ typesOfModelsCard: { ...(data || {}), loading: false } });

export const dashboardRequestFirmwareCard = (state) => state.merge({ distributionOfFirmwareCard: { loading: true } });
export const dashboardResponseFirmwareCard = (state, { data }) =>
  state.merge({ distributionOfFirmwareCard: { ...(data || {}), loading: false } });


export const dashboardRequestScannerFirmwareCard = (state) => state.merge({ scannerFirmwareCard: { loading: true } });
export const dashboardResponseScannerFirmwareCard = (state, { data }) =>
    state.merge({ scannerFirmwareCard: { ...(data || {}), loading: false } });

export const dashboardRequestOsCard = (state) => state.merge({ distributionOfOsCard: { loading: true } });
export const dashboardResponseOsCard = (state, { data }) =>
  state.merge({ distributionOfOsCard: { ...(data || {}), loading: false } });

export const dashboardRequestScanVolume = (state) => state.merge({ scanVolume: { loading: true } });
export const dashboardResponseScanVolume = (state, { data }) =>
  state.merge({ scanVolume: { ...(data || {}), loading: false } });

export const dashboardDroppedDevicesCardRequest = (state) => state.merge({ droppedDevicesCard: { loading: true } });
export const dashboardDroppedDevicesCardSuccess = (state, { data, statusCode }) =>
  state.merge({ droppedDevicesCard: { loading: false, data, statusCode } });
export const dashboardDroppedDevicesCardFailure = (state, { statusCode }) =>
  state.merge({ droppedDevicesCard: { loading: false, statusCode } });

export const dashboardToggleLayoutEdit = (state) => state.merge({ editing: !state.editing });

export const genericRequest = (state, { reqType, extraParam, paramDefaultValue }) => {
  const paramName = extraParam ? `battery${reqType}${extraParam}${paramDefaultValue}` : `battery${reqType}`;
  return state.merge({
    loading: true,
    [paramName]: {
      loading: true,
    },
  });
};

export const genericFailure = (state, { reqType }) =>
  state.merge({
    loading: false,
    error: true,
    [reqType]: {
      loading: false,
      error: true,
    },
  });

export const genericSuccess = (state, { reqType, data }) =>
  state.merge({
    loading: false,
    error: false,
    [reqType]: {
      loading: false,
      error: false,
      ...(data || {}),
    },
  });

export const dashboardRequestBatteriesLowHealthEventsCard = (state) =>
  state.merge({ batteryHealthEvents: { loading: true } });
export const dashboardResponseBatteriesLowHealthEventsCard = (state, { data }) =>
  state.merge({ batteryHealthEvents: { ...(data || {}), loading: false } });

export const dashboardRequestUndockedChargeCard = (state) => state.merge({ chargeWhenUndockedCard: { loading: true } });
export const dashboardResponseUndockedChargeCard = (state, { data }) =>
  state.merge({ chargeWhenUndockedCard: { ...(data || {}), loading: false } });

export const dashboardRequestTotalDevicesStatus = (state, { hours }) =>
  state.merge({ totalDevicesStatus: { loading: true }, hoursOffline: hours || 72 });

export const dashboardResponseTotalDevicesStatus = (state, { data }) =>
  state.merge({ totalDevicesStatus: { ...(data || {}), loading: false } });

export const dashboardRequestPrintVolumeCard = (state) => state.merge({ printVolumeCard: { loading: true } });
export const dashboardResponsePrintVolumeCard = (state, { data }) =>
  state.merge({ printVolumeCard: { ...(data || {}), loading: false } });

export const dashboardRequestHardUseDevicesCard = (state, { hoursOfUsePerDay }) =>
  state.merge({ hardUseDevicesCard: { loading: true }, hoursOfUsePerDay: hoursOfUsePerDay || 8 });

export const dashboardResponseHardUseDevicesCard = (state, { data }) =>
  state.merge({ hardUseDevicesCard: { ...(data || {}), loading: false } });

export const dashboardSetHardUseDevicesCard = (state, { hoursOfUsePerDay }) => state.merge({ hoursOfUsePerDay });

export const dashboardRequestHoursOfUseCard = (state) => state.merge({ hoursOfUseCard: { loading: true } });
export const dashboardResponseHoursOfUseCard = (state, { data }) =>
  state.merge({ hoursOfUseCard: { ...(data || {}), loading: false } });

export const dashboardRequestScanVolumeByDevice = (state) => state.merge({ scanVolumeByDevice: { loading: true } });
export const dashboardResponseScanVolumeByDevice = (state, { data }) =>
  state.merge({ scanVolumeByDevice: { ...(data || {}), loading: false } });

export const dashboardRequestPrintVolumeByDevice = (state) =>
  state.merge({ printVolumeByDeviceCard: { loading: true } });
export const dashboardResponsePrintVolumeByDevice = (state, { data }) =>
  state.merge({ printVolumeByDeviceCard: { ...(data || {}), loading: false } });

export const dashboardRequestSecurityPatch = (state) => state.merge({ devicesSecurityPatch: { loading: true } });
export const dashboardResponseSecurityPatch = (state, { data }) =>
  state.merge({ devicesSecurityPatch: { ...(data || {}), loading: false } });

export const dashboardRequestHardRebootsByDevice = (state) => state.merge({ hardRebootsByDevice: { loading: true } });
export const dashboardResponseHardRebootsByDevice = (state, { data }) =>
  state.merge({ hardRebootsByDevice: { ...(data || {}), loading: false } });

export const dashboardRequestErrorsByDevice = (state) => state.merge({ errorsByDevice: { loading: true } })
export const dashboardResponseErrorsByDevice = (state, { data }) => state.merge({ errorsByDevice: { ...(data || {}), loading: false } })

export const dashboardRequestAppData = (state) => state.merge({ appData: { loading: true } });
export const dashboardResponseAppData = (state, { data }) =>
  state.merge({
    appData: {
      ...(data || {}),
      loading: false,
    },
    appDataCustom: {
      ...(data || {}),
      loading: false,
    },
  });

export const dashboardRequestInventoryCyclesByDevice = (state) => state.merge({ inventoryCyclesByDevice: { loading: true } })
export const dashboardResponseInventoryCyclesByDevice = (state, { data }) => state.merge({ inventoryCyclesByDevice: { ...(data || {}), loading: false } })


export const dashboardRequestDeepSleepData = (state) => state.merge({ deepSleeps: { loading: true } })
export const dashboardResponseDeepSleepData = (state, { data }) => state.merge({
  deepSleeps: {
    ...(data || {}),
    loading: false
  }
})

export const dashboardRequestAppDataCustom = (state) => state.merge({ appDataCustom: { loading: true } });
export const dashboardResponseAppDataCustom = (state, { data }) =>
  state.merge({
    appDataCustom: {
      ...(data || {}),
      loading: false,
    },
  });

export const dashboardRequestUsagePerDayByDevice = (state) => state.merge({ usagePerDayByDevice: { loading: true } });
export const dashboardResponseUsagePerDayByDevice = (state, { data }) =>
  state.merge({ usagePerDayByDevice: { ...(data || {}), loading: false } });

export const dashboardRequestPrintedMetersByDevice = (state) =>
  state.merge({ printedMetersByDevice: { loading: true } });
export const dashboardResponsePrintedMetersByDevice = (state, { data }) =>
  state.merge({ printedMetersByDevice: { ...(data || {}), loading: false } });

export const dashboardRequestDroppedDevicesByDevice = (state) =>
  state.merge({ droppedDevicesCardByDevice: { loading: true } });
export const dashboardResponseDroppedDevicesByDevice = (state, { data }) =>
  state.merge({ droppedDevicesCardByDevice: { ...(data || {}), loading: false } });

export const dashboardRequestNewBatteriesLastMonthCard = (state) =>
  state.merge({ newBatteriesLastMonth: { loading: true } });
export const dashboardResponseNewBatteriesLastMonthCard = (state, { data }) =>
  state.merge({ newBatteriesLastMonth: { ...(data || {}), loading: false } });

export const dashboardOpenDrilldown = (state, { key, title, drillDownOptions }) =>
  state.updateIn(['drilldown'], () => ({
    data: state[key],
    dataKey: key,
    isOpen: true,
    title,
    drillDownOptions,
  }));
export const dashboardUpdateDrilldown = (state, { key }) =>
  state.updateIn(['drilldown'], (drilldownData) => ({
    ...drilldownData,
    data: state[key],
  }));
export const dashboardCloseDrilldown = (state) =>
  state.updateIn(['drilldown'], () => ({
    data: [],
    dataKey: null,
    isOpen: false,
    title: '',
    drillDownOptions: {},
  }));

export const dashboardRequestTotalBatteriesDetailsNextPage = (state) => state;
export const dashboardResponseTotalBatteriesDetailsNextPage = (state, { data }) => {
  return state.updateIn(['totalBatteriesCard'], (value) => ({
    ...value,
    data: [...value.data, ...data.data],
    total: data.total,
    sites: data.sites,
    includeChildren: data.includeChildren,
    query: data.query,
    loading: false
  }));
};

export const dashboardRequestTotalBatteriesDetails = (state) =>
  state.updateIn(['totalBatteriesCard', 'loading'], () => true);
export const dashboardResetDataNotReportingBatteries = (state) => {
  return state.updateIn(['notReportingBatteriesCard'], (value) => ({
    ...value,
    data: []
  }));
}
export const dashboardResponseTotalBatteriesDetails = (state, { data }) => {
  return state.updateIn(['totalBatteriesCard'], (value) => ({
    ...value,
    data: data.data,
    total: data.total,
    sites: data.sites,
    includeChildren: data.includeChildren,
    query: data.query,
    loading: false
  }));
};

export const dashboardRequestNotReportingBatteries = (state) =>
  state.updateIn(['notReportingBatteriesCard', 'loading'], () => true);


export const dashboardResponseNotReportingBatteries = (state, { data }) => {
  return state.updateIn(['notReportingBatteriesCard'], (value) => ({
    ...value,
    data: data.data,
    total: data.total,
    sites: data.sites,
    includeChildren: data.includeChildren,
    query: data.query,
    loading: false
  }));
};

export const dashboardRequestNotReportingBatteriesNextPage = (state) => state;

export const dashboardResponseNotReportingBatteriesNextPage = (state, { data }) => {
  return state.updateIn(['notReportingBatteriesCard'], (value) => ({
    ...value,
    data: [...value.data, ...data.data],
    total: data.total,
    sites: data.sites,
    includeChildren: data.includeChildren,
    query: data.query,
    loading: false
  }));
};


export const dashboardRequestNotReportingBatteriesCard = (state, params) => {
  const days = state.getIn(['notReportingDays'], 30);
  return state.merge({
    notReportingBatteriesCard: { ...state.notReportingBatteriesCard, loading: true },
    notReportingDays: parseInt(params.params.notReportedDays) || days,
  });
};

export const dashboardResponseNotReportingBatteriesCard = (state, { data }) => {
  return state.updateIn(['notReportingBatteriesCard'], (value) => {
    return {
      ...value,
      totalBatteriesDashboard: data.totalBatteries,
      statusCode: data.statusCode,
      loading: false,
    };
  });
};

export const dashboardRequestDeleteBatteries = (state, { batteryList }) =>
  state.setIn(['totalBatteriesCard', 'loading'], true);

export const dashboardResponseDeleteBatteries = (state, { batteryList }) => {
  return state.updateIn(['totalBatteriesCard'], (details) => {
    return {
      ...details,
      data: [
        ...details.data.filter(
          (battery) => !batteryList.map((b) => b.batterySerialNumber).includes(battery.serialNumber)
        ),
      ],
      total: details.total - batteryList.length,
      totalBatteriesDashboard: details.totalBatteriesDashboard - batteryList.length,
      loading: false,
    };
  });
};

export const dashboardRequestDeletedBatteries = (state, { params }) =>
  state.updateIn(['deletedBatteriesCard', 'loading'], () => true);

export const dashboardResetDataDeletedBatteries = (state) => {
  return state.updateIn(['deletedBatteriesCard'], (value) => ({
    ...value,
    data: []
  }));
}

export const dashboardResponseDeletedBatteries = (state, { data }) => {
  return state.updateIn(['deletedBatteriesCard'], (value) => ({
    ...value,
    data: data.data,
    total: data.total,
    sites: data.sites,
    includeChildren: data.includeChildren,
    query: data.query,
    loading: false
  }));
};

export const dashboardRequestDeletedBatteriesNextPage = (state) => state;

export const dashboardResponseDeletedBatteriesNextPage = (state, { data }) => {
  return state.updateIn(['deletedBatteriesCard'], (value) => ({
    ...value,
    data: [...value.data, ...data.data],
    total: data.total,
    sites: data.sites,
    includeChildren: data.includeChildren,
    query: data.query,
    loading: false
  }));
};

export const dashboardRequestDeleteBatteriesNotReporting = (state, { batteryList }) =>
  state.setIn(['notReportingBatteriesCard', 'loading'], true);

export const dashboardResponseDeleteBatteriesNotReporting = (state, { batteryList }) => {
  return state.updateIn(['notReportingBatteriesCard'], (details) => {
    return {
      ...details,
      data: [
        ...details.data.filter(
          (battery) => !batteryList.map((b) => b.batterySerialNumber).includes(battery.serialNumber)
        ),
      ],
      total: details.total - batteryList.length,
      totalBatteriesDashboard: details.totalBatteriesDashboard - batteryList.length,
      loading: false,
    };
  });
};

export const dashboardRequestAddBatteries = (state, { batteryList }) =>
  state.setIn(['deletedBatteriesCard', 'loading'], true);

export const dashboardResponseAddBatteries = (state, { batteryList }) => {
  return state.updateIn(['deletedBatteriesCard'], (details) => {
    return {
      ...details,
      data: [
        ...details.data.filter(
          (battery) => !batteryList.map((b) => b.batterySerialNumber).includes(battery.serialNumber)
        ),
      ],
      total: details.total - batteryList.length,
      totalBatteriesDashboard: details.totalBatteriesDashboard - batteryList.length,
      loading: false,
    };
  });
};

export const dashboardRequestDeletedBatteriesCard = (state, { params }) =>
  state.setIn(['deletedBatteriesCard', 'loading'], true);

export const dashboardResponseDeletedBatteriesCard = (state, { data }) => {
  return state.updateIn(['deletedBatteriesCard'], (value) => {
    return {
      ...value,
      totalBatteriesDashboard: data.totalBatteries,
      statusCode: data.statusCode,
      loading: false,
    };
  });
};

export const dashboardRequestApplicationUsageCardNextPage = (state) =>
  state.setIn(['applicationUsage', 'loading'], true);
export const dashboardResponseApplicationUsageCardNextPage = (state, { data }) =>
  state.updateIn(['applicationUsage'], (details) => ({
    ...details,
    data: [...details.data, ...data.data],
    loading: false,
  }));

export const dashboardRequestApplicationUsageCard = (state, { params }) =>
  state.setIn(['applicationUsage', 'loading'], true).setIn(['applicationUsage', 'days'], params.days || 0);

export const dashboardResponseApplicationUsageCard = (state, { data }) =>
  state.merge({
    applicationUsage: {
      ...(data || {}),
      loading: false,
      days: data.days,
    },
  });

export const dashboardRequestNetworkIOCard = (state, { params }) =>
  state.setIn(['networkIO', 'loading'], true).setIn(['networkIO', 'days'], params.days || 0);

export const dashboardResponseNetworkIOCard = (state, { data }) =>
  state.merge({
    networkIO: {
      ...(data || {}),
      loading: false,
      days: data.days,
    },
  });

export const dashboardRequestNetworkIONextPage = (state) => state.setIn(['loading'], true);
export const dashboardResponseNetworkIONextPage = (state, { data }) =>
  state.updateIn(['networkIO'], (details) => ({
    ...details,
    data: [...details.data, ...data.data],
    loading: false,
  }));

export const dashboardRequestApplicationUsageDetails = (state) => state.setIn(['appUsageDetails', 'loading'], true);
export const dashboardRequestApplicationUsageDetailsNextPage = (state) =>
  state.setIn(['appUsageDetails', 'loading'], true);
export const dashboardResponseApplicationUsageDetails = (state, { data }) => {
  return state.updateIn(['appUsageDetails'], (appUsageDetails) => ({
    ...appUsageDetails,
    [data.appName]: {
      data: [...data.data],
      total: data.total,
      loading: false,
    },
    loading: false,
  }));
};

export const dashboardResponseApplicationUsageDetailsNextPage = (state, { data }) => {
  return state.updateIn(['appUsageDetails'], (appUsageDetails) => ({
    ...appUsageDetails,
    [data.appName]: {
      data: [...appUsageDetails[data.appName].data, ...data.data],
      total: data.total,
      loading: false,
    },
    loading: false,
  }));
};

export const dashboardRequestHardUseDevicesDetails = (state) =>
  state.merge({ hardUseDevicesCard: { ...state['hardUseDevicesCard'], details: { loading: true } } });
// export const dashboardRequestHardUseDevicesDetails = (state) => state.merge({ loading:true })
export const dashboardResponseHardUseDevicesDetails = (state, { data }) =>
  state.merge({
    hardUseDevicesCard: { ...state['hardUseDevicesCard'], details: { detailsBySite: data.days, loading: false } },
  });
export const dashboardGenericResponseHardUseDevicesDetails = (state) =>
  state.updateIn(['hardUseDevicesCard', 'details'], () => {
    return { loading: false };
  });

export const dashboardDockingTimeHardUseDevicesCard = (state, { dockingTime }) =>
  state.merge({ hardUseDevicesCard: { ...state['hardUseDevicesCard'], dockingTime: dockingTime } });

export const dashboardRequestLostAndFound = (state) => state.setIn(['lostAndFound', 'loading'], true);
export const dashboardResponseLostAndFound = (state, { data }) =>
  state.setIn(['lostAndFound'], data)
    .setIn(['lostAndFound', 'loading'], false)

export const dashboardRequestApplicationDisruptions = (state) => state.setIn(['applicationDisruptions', 'loading'], true);
export const dashboardResponseApplicationDisruptions = (state, { data }) =>
  state.setIn(['applicationDisruptions'], data)
    .setIn(['applicationDisruptions', 'loading'], false)

export const dashboardRequestApplicationDisruptionsByApp = (state) => state.setIn(['applicationDisruptionsByApp', 'loading'], true);
export const dashboardResponseApplicationDisruptionsByApp = (state, { data }) =>
  state.setIn(['applicationDisruptionsByApp'], data)
    .setIn(['applicationDisruptionsByApp', 'loading'], false)

export const dashboardRequestApplicationDisruptionsByDevice = (state) => state.setIn(['applicationDisruptions', 'loading'], true );
export const dashboardResponseApplicationDisruptionsByDevice = (state, { data }) =>
state.setIn(['applicationDisruptions'], data)
  .setIn(['applicationDisruptions', 'loading'], false)

export const dashboardRequestLostAndFoundDetails = (state) => state.setIn(['lostAndFound', 'loading'], true);

export const dashboardResetDataLostAndFoundDetails = (state) => {
  return state.updateIn(['lostAndFound'], (value) => ({
    ...value,
    data: []
  }));
}

export const dashboardResponseLostAndFoundDetails = (state, { data }) =>
  state.setIn(['lostAndFound', 'details'], data)
    .setIn(['lostAndFound', 'loading'], false)


export const dashboardResetDataApplicationDisruptionsDetails = (state) => {
  return state.updateIn(['applicationDisruptionsDetails'], (value) => ({
    ...value,
    data: [],
  }));
}

export const dashboardRequestApplicationDisruptionsDetails = (state) =>
  state.updateIn(['applicationDisruptionsDetails', 'loading'], () => true);

export const dashboardResponseApplicationDisruptionsDetails = (state, { data }) => {
  return state.updateIn(['applicationDisruptionsDetails'], (value) => ({
    ...value,
    data: data.data,
    total: data.total,
    statusCode: data.statusCode,
    loading: false
  }));
}
export const dashboardRequestApplicationDisruptionsDetailsNextPage = (state) =>
  state.updateIn(['applicationDisruptionsDetails', 'loading'], () => true);

export const dashboardResponseApplicationDisruptionsDetailsNextPage = (state, { data }) => {
  return state.updateIn(['applicationDisruptionsDetails'], (value) => ({
    ...value,
    data: [...value.data, ...data.data],
    total: data.total,
    statusCode: data.statusCode,
    loading: false
  }));
}

export const dashboardRequestApplicationDisruptionsByAppDetails = (state) =>
  state.setIn(['applicationDisruptionsByAppDetails', 'loading'], true);

export const dashboardResponseApplicationDisruptionsByAppDetails = (state, { data }) => {
  return state.updateIn(['applicationDisruptionsByAppDetails'], (value) => ({
    ...value,
    data: data.data,
    total: data.total,
    statusCode: data.statusCode,
    loading: false
  }));
}
export const dashboardRequestApplicationDisruptionsByAppDetailsNextPage = (state) =>
  state.setIn(['applicationDisruptionsByAppDetails', 'loading'], () => true);

export const dashboardResponseApplicationDisruptionsByAppDetailsNextPage = (state, { data }) => {
  return state.updateIn(['applicationDisruptionsByAppDetails'], (value) => ({
    ...value,
    data: [...value.data, ...data.data],
    total: data.total,
    statusCode: data.statusCode,
    loading: false
  }));
}

export const dashboardResetDataApplicationDisruptionsByAppDetails = (state) => {
  return state.setIn(['applicationDisruptionsByAppDetails'], (value) => ({
    ...value,
    data: [],
  }));
}


export const reducer = createReducer(INITIAL_STATE, {
  [Types.DASHBOARD_TOGGLE_LAYOUT_EDIT]: dashboardToggleLayoutEdit,
  [Types.DASHBOARD_REQUEST_AVG_BATTERY_AGE_CARD]: dashboardRequestAvgBatteryAgeCard,
  [Types.DASHBOARD_RESPONSE_AVG_BATTERY_AGE_CARD]: dashboardResponseAvgBatteryAgeCard,
  [Types.DASHBOARD_REQUEST_DEVICE_USAGE_LEVEL]: dashboardRequestDeviceUsageLevel,
  [Types.DASHBOARD_DOCKING_TIME_DEVICE_USAGE_LEVEL]: dashboardDockingTimeDeviceUsageLevel,
  [Types.DASHBOARD_RESPONSE_DEVICE_USAGE_LEVEL]: dashboardResponseDeviceUsageLevel,
  [Types.DASHBOARD_REQUEST_PRINTER_USAGE_LEVEL_BY_METERS]: dashboardRequestPrinterUsageLevelByMeters,
  [Types.DASHBOARD_RESPONSE_PRINTER_USAGE_LEVEL_BY_METERS]: dashboardResponsePrinterUsageLevelByMeters,
  [Types.DASHBOARD_REQUEST_MOBILE_PRINTER_USAGE_LEVEL_BY_METERS]: dashboardRequestMobilePrinterUsageLevelByMeters,
  [Types.DASHBOARD_RESPONSE_MOBILE_PRINTER_USAGE_LEVEL_BY_METERS]: dashboardResponseMobilePrinterUsageLevelByMeters,
  [Types.DASHBOARD_REQUEST_AVG_BATTERY_CYCLE_CARD]: dashboardRequestAvgBatteryCycleCard,
  [Types.DASHBOARD_RESPONSE_AVG_BATTERY_CYCLE_CARD]: dashboardResponseAvgBatteryCycleCard,
  [Types.DASHBOARD_REQUEST_SCAN_PULLS_CARD]: dashboardRequestScanPullsCard,
  [Types.DASHBOARD_RESPONSE_SCAN_PULLS_CARD]: dashboardResponseScanPullsCard,
  [Types.DASHBOARD_REQUEST_PRINTERS_OUT_OF_PAPER_CARD]: dashboardRequestPrintersOutOfPaperCard,
  [Types.DASHBOARD_RESPONSE_PRINTERS_OUT_OF_PAPER_CARD]: dashboardResponsePrintersOutOfPaperCard,
  [Types.DASHBOARD_REQUEST_PRINTERS_OUT_OF_RIBBON_CARD]: dashboardRequestPrintersOutOfRibbonCard,
  [Types.DASHBOARD_RESPONSE_PRINTERS_OUT_OF_RIBBON_CARD]: dashboardResponsePrintersOutOfRibbonCard,
  [Types.DASHBOARD_REQUEST_EVENTS_FOR_PRINTERS]: dashboardRequestEventsForPrinters,
  [Types.DASHBOARD_RESPONSE_EVENTS_FOR_PRINTERS]: dashboardResponseEventsForPrinters,
  [Types.DASHBOARD_REQUEST_PTC_DEVICES_USE]: dashboardRequestPtcDevicesUse,
  [Types.DASHBOARD_RESPONSE_PTC_DEVICES_USE]: dashboardResponsePtcDevicesUse,
  [Types.DASHBOARD_REQUEST_HARD_REBOOTS_CARD]: dashboardRequestHardRebootsCard,
  [Types.DASHBOARD_RESPONSE_HARD_REBOOTS_CARD]: dashboardResponseHardRebootsCard,
  [Types.DASHBOARD_REQUEST_BATTERY_SWAPS]: dashboardRequestBatterySwaps,
  [Types.DASHBOARD_RESPONSE_BATTERY_SWAPS]: dashboardResponseBatterySwaps,
  [Types.DASHBOARD_SET_ABOVE_BATTERY_CYCLE]: dashboardSetAboveBatteryCycle,
  [Types.DASHBOARD_SET_SITE_ID]: dashboardSetSiteId,
  [Types.DASHBOARD_CLEAN_SITE_ID]: dashboardCleanSiteId,
  [Types.DASHBOARD_REQUEST_ALERTS]: dashboardRequestAlerts,
  [Types.DASHBOARD_SUCCESS_ALERTS]: dashboardSuccessAlerts,
  [Types.DASHBOARD_FAILURE_ALERTS]: dashboardFailureAlerts,
  [Types.DASHBOARD_REQUEST_PRINTER_USAGE_LEVEL_BY_LABELS]: dashboardRequestPrinterUsageLevelByLabels,
  [Types.DASHBOARD_RESPONSE_PRINTER_USAGE_LEVEL_BY_LABELS]: dashboardResponsePrinterUsageLevelByLabels,
  [Types.DASHBOARD_REQUEST_MOBILE_PRINTER_USAGE_LEVEL_BY_LABELS]: dashboardRequestMobilePrinterUsageLevelByLabels,
  [Types.DASHBOARD_RESPONSE_MOBILE_PRINTER_USAGE_LEVEL_BY_LABELS]: dashboardResponseMobilePrinterUsageLevelByLabels,
  [Types.DASHBOARD_REQUEST_AGING_BATTERIES_CARD]: dashboardRequestAgingBatteriesCard,
  [Types.DASHBOARD_RESPONSE_AGING_BATTERIES_CARD]: dashboardResponseAgingBatteriesCard,
  [Types.DASHBOARD_SET_AGING_BATTERIES_CARD]: dashboardSetAgingBatteriesCard,
  [Types.DASHBOARD_REQUEST_BATTERIES_LOW_HEALTH_CARD]: dashboardRequestBatteriesLowHealthCard,
  [Types.DASHBOARD_RESPONSE_BATTERIES_LOW_HEALTH_CARD]: dashboardResponseBatteriesLowHealthCard,
  [Types.DASHBOARD_SET_BATTERIES_LOW_HEALTH_CARD]: dashboardSetBatteriesLowHealthCard,
  [Types.DASHBOARD_REQUEST_DISTRIBUTION_OF_DIPS_CARD]: dashboardRequestDistributionOfDipsCard,
  [Types.DASHBOARD_RESPONSE_DISTRIBUTION_OF_DIPS_CARD]: dashboardResponseDistributionOfDipsCard,
  [Types.DASHBOARD_REQUEST_TOTAL_DEVICES_CARD]: dashboardRequestTotalDevicesCard,
  [Types.DASHBOARD_RESPONSE_TOTAL_DEVICES_CARD]: dashboardResponseTotalDevicesCard,
  [Types.DASHBOARD_REQUEST_TOTAL_DEVICES_WITH_BATTERIES_CARD]: dashboardRequestTotalDevicesWithBatteriesCard,
  [Types.DASHBOARD_RESPONSE_TOTAL_DEVICES_WITH_BATTERIES_CARD]: dashboardResponseTotalDevicesWithBatteriesCard,
  [Types.DASHBOARD_REQUEST_TOTAL_BATTERIES_CARD]: dashboardRequestTotalBatteriesCard,
  [Types.DASHBOARD_RESPONSE_TOTAL_BATTERIES_CARD]: dashboardResponseTotalBatteriesCard,
  [Types.DASHBOARD_REQUEST_TOTAL_DEVICES_WITHOUT_BATTERIES_CARD]: dashboardRequestTotalDevicesWithoutBatteriesCard,
  [Types.DASHBOARD_RESPONSE_TOTAL_DEVICES_WITHOUT_BATTERIES_CARD]: dashboardResponseTotalDevicesWithoutBatteriesCard,
  [Types.DASHBOARD_REQUEST_FIXED_DEVICES_OFFLINE_CARD]: dashboardRequestFixedDevicesOfflineCard,
  [Types.DASHBOARD_RESPONSE_FIXED_DEVICES_OFFLINE_CARD]: dashboardResponseFixedDevicesOfflineCard,
  [Types.DASHBOARD_REQUEST_BATTERIES_CYCLE_ABOVE_CARD]: dashboardRequestBatteriesCycleAboveCard,
  [Types.DASHBOARD_RESPONSE_BATTERIES_CYCLE_ABOVE_CARD]: dashboardResponseBatteriesCycleAboveCard,
  [Types.DASHBOARD_REQUEST_BATTERIES_HEALTH_BELOW_CARD]: dashboardRequestBatteriesHealthBelowCard,
  [Types.DASHBOARD_RESPONSE_BATTERIES_HEALTH_BELOW_CARD]: dashboardResponseBatteriesHealthBelowCard,
  [Types.DASHBOARD_REQUEST_BATTERIES_HEALTH_BETWEEN_CARD]: dashboardRequestBatteriesHealthBetweenCard,
  [Types.DASHBOARD_RESPONSE_BATTERIES_HEALTH_BETWEEN_CARD]: dashboardResponseBatteriesHealthBetweenCard,
  [Types.DASHBOARD_REQUEST_TYPES_OF_DEVICES_CARD]: dashboardRequestTypesOfDevicesCard,
  [Types.DASHBOARD_RESPONSE_TYPES_OF_DEVICES_CARD]: dashboardResponseTypesOfDevicesCard,
  [Types.DASHBOARD_REQUEST_TYPES_OF_MODELS_CARD]: dashboardRequestTypesOfModelsCard,
  [Types.DASHBOARD_RESPONSE_TYPES_OF_MODELS_CARD]: dashboardResponseTypesOfModelsCard,
  [Types.DASHBOARD_REQUEST_OS_CARD]: dashboardRequestOsCard,
  [Types.DASHBOARD_RESPONSE_OS_CARD]: dashboardResponseOsCard,
  [Types.DASHBOARD_GENERIC_REQUEST]: genericRequest,
  [Types.DASHBOARD_GENERIC_FAILURE]: genericFailure,
  [Types.DASHBOARD_GENERIC_SUCCESS]: genericSuccess,
  [Types.DASHBOARD_RESPONSE_BATTERIES_LOW_HEALTH_EVENTS_CARD]: dashboardResponseBatteriesLowHealthEventsCard,
  [Types.DASHBOARD_REQUEST_BATTERIES_LOW_HEALTH_EVENTS_CARD]: dashboardRequestBatteriesLowHealthEventsCard,
  [Types.DASHBOARD_RESPONSE_UNDOCKED_CHARGE_CARD]: dashboardResponseUndockedChargeCard,
  [Types.DASHBOARD_REQUEST_UNDOCKED_CHARGE_CARD]: dashboardRequestUndockedChargeCard,
  [Types.DASHBOARD_REQUEST_TOTAL_DEVICES_STATUS]: dashboardRequestTotalDevicesStatus,
  [Types.DASHBOARD_RESPONSE_TOTAL_DEVICES_STATUS]: dashboardResponseTotalDevicesStatus,
  [Types.DASHBOARD_DROPPED_DEVICES_CARD_REQUEST]: dashboardDroppedDevicesCardRequest,
  [Types.DASHBOARD_DROPPED_DEVICES_CARD_SUCCESS]: dashboardDroppedDevicesCardSuccess,
  [Types.DASHBOARD_DROPPED_DEVICES_CARD_FAILURE]: dashboardDroppedDevicesCardFailure,
  [Types.DASHBOARD_REQUEST_FIRMWARE_CARD]: dashboardRequestFirmwareCard,
  [Types.DASHBOARD_RESPONSE_FIRMWARE_CARD]: dashboardResponseFirmwareCard,
  [Types.DASHBOARD_REQUEST_SCANNER_FIRMWARE_CARD]: dashboardRequestScannerFirmwareCard,
  [Types.DASHBOARD_RESPONSE_SCANNER_FIRMWARE_CARD]: dashboardResponseScannerFirmwareCard,
  [Types.DASHBOARD_REQUEST_SCAN_VOLUME]: dashboardRequestScanVolume,
  [Types.DASHBOARD_RESPONSE_SCAN_VOLUME]: dashboardResponseScanVolume,
  [Types.DASHBOARD_REQUEST_PRINT_VOLUME_CARD]: dashboardRequestPrintVolumeCard,
  [Types.DASHBOARD_RESPONSE_PRINT_VOLUME_CARD]: dashboardResponsePrintVolumeCard,
  [Types.DASHBOARD_REQUEST_PRINTERS_FAULTY_DOTS]: dashboardRequestPrintersFaultyDots,
  [Types.DASHBOARD_RESPONSE_PRINTERS_FAULTY_DOTS]: dashboardResponsePrintersFaultyDots,
  [Types.DASHBOARD_REQUEST_HARD_USE_DEVICES_CARD]: dashboardRequestHardUseDevicesCard,
  [Types.DASHBOARD_RESPONSE_HARD_USE_DEVICES_CARD]: dashboardResponseHardUseDevicesCard,
  [Types.DASHBOARD_SET_HARD_USE_DEVICES_CARD]: dashboardSetHardUseDevicesCard,
  [Types.DASHBOARD_REQUEST_HOURS_OF_USE_CARD]: dashboardRequestHoursOfUseCard,
  [Types.DASHBOARD_RESPONSE_HOURS_OF_USE_CARD]: dashboardResponseHoursOfUseCard,
  [Types.DASHBOARD_REQUEST_SCAN_VOLUME_BY_DEVICE]: dashboardRequestScanVolumeByDevice,
  [Types.DASHBOARD_RESPONSE_SCAN_VOLUME_BY_DEVICE]: dashboardResponseScanVolumeByDevice,
  [Types.DASHBOARD_REQUEST_PRINT_VOLUME_BY_DEVICE]: dashboardRequestPrintVolumeByDevice,
  [Types.DASHBOARD_RESPONSE_PRINT_VOLUME_BY_DEVICE]: dashboardResponsePrintVolumeByDevice,
  [Types.DASHBOARD_REQUEST_SECURITY_PATCH]: dashboardRequestSecurityPatch,
  [Types.DASHBOARD_RESPONSE_SECURITY_PATCH]: dashboardResponseSecurityPatch,
  [Types.DASHBOARD_REQUEST_HARD_REBOOTS_BY_DEVICE]: dashboardRequestHardRebootsByDevice,
  [Types.DASHBOARD_RESPONSE_HARD_REBOOTS_BY_DEVICE]: dashboardResponseHardRebootsByDevice,
  [Types.DASHBOARD_REQUEST_ERRORS_BY_DEVICE]: dashboardRequestErrorsByDevice,
  [Types.DASHBOARD_RESPONSE_ERRORS_BY_DEVICE]: dashboardResponseErrorsByDevice,
  [Types.DASHBOARD_REQUEST_INVENTORY_CYCLES_BY_DEVICE]: dashboardRequestInventoryCyclesByDevice,
  [Types.DASHBOARD_RESPONSE_INVENTORY_CYCLES_BY_DEVICE]: dashboardResponseInventoryCyclesByDevice,
  [Types.DASHBOARD_REQUEST_APP_DATA]: dashboardRequestAppData,
  [Types.DASHBOARD_RESPONSE_APP_DATA]: dashboardResponseAppData,
  [Types.DASHBOARD_REQUEST_APP_DATA_CUSTOM]: dashboardRequestAppDataCustom,
  [Types.DASHBOARD_RESPONSE_APP_DATA_CUSTOM]: dashboardResponseAppDataCustom,
  [Types.DASHBOARD_REQUEST_DEEP_SLEEP_DATA]: dashboardRequestDeepSleepData,
  [Types.DASHBOARD_RESPONSE_DEEP_SLEEP_DATA]: dashboardResponseDeepSleepData,
  [Types.DASHBOARD_RESPONSE_USAGE_PER_DAY_BY_DEVICE]: dashboardResponseUsagePerDayByDevice,
  [Types.DASHBOARD_REQUEST_PRINTED_METERS_BY_DEVICE]: dashboardRequestPrintedMetersByDevice,
  [Types.DASHBOARD_RESPONSE_PRINTED_METERS_BY_DEVICE]: dashboardResponsePrintedMetersByDevice,
  [Types.DASHBOARD_REQUEST_DROPPED_DEVICES_BY_DEVICE]: dashboardRequestDroppedDevicesByDevice,
  [Types.DASHBOARD_RESPONSE_DROPPED_DEVICES_BY_DEVICE]: dashboardResponseDroppedDevicesByDevice,
  [Types.DASHBOARD_REQUEST_NEW_BATTERIES_LAST_MONTH_CARD]: dashboardRequestNewBatteriesLastMonthCard,
  [Types.DASHBOARD_RESPONSE_NEW_BATTERIES_LAST_MONTH_CARD]: dashboardResponseNewBatteriesLastMonthCard,
  [Types.DASHBOARD_OPEN_DRILLDOWN]: dashboardOpenDrilldown,
  [Types.DASHBOARD_UPDATE_DRILLDOWN]: dashboardUpdateDrilldown,
  [Types.DASHBOARD_CLOSE_DRILLDOWN]: dashboardCloseDrilldown,
  [Types.DASHBOARD_REQUEST_TOTAL_BATTERIES_DETAILS]: dashboardRequestTotalBatteriesDetails,
  [Types.DASHBOARD_RESET_DATA_TOTAL_BATTERIES]: dashboardResetDataTotalBatteries,
  [Types.DASHBOARD_RESPONSE_TOTAL_BATTERIES_DETAILS]: dashboardResponseTotalBatteriesDetails,
  [Types.DASHBOARD_REQUEST_TOTAL_BATTERIES_DETAILS_NEXT_PAGE]: dashboardRequestTotalBatteriesDetailsNextPage,
  [Types.DASHBOARD_RESPONSE_TOTAL_BATTERIES_DETAILS_NEXT_PAGE]: dashboardResponseTotalBatteriesDetailsNextPage,
  [Types.DASHBOARD_REQUEST_NOT_REPORTING_BATTERIES]: dashboardRequestNotReportingBatteries,
  [Types.DASHBOARD_RESET_DATA_NOT_REPORTING_BATTERIES]: dashboardResetDataNotReportingBatteries,
  [Types.DASHBOARD_RESPONSE_NOT_REPORTING_BATTERIES]: dashboardResponseNotReportingBatteries,
  [Types.DASHBOARD_REQUEST_NOT_REPORTING_BATTERIES_NEXT_PAGE]: dashboardRequestNotReportingBatteriesNextPage,
  [Types.DASHBOARD_RESPONSE_NOT_REPORTING_BATTERIES_NEXT_PAGE]: dashboardResponseNotReportingBatteriesNextPage,
  [Types.DASHBOARD_REQUEST_NOT_REPORTING_BATTERIES_CARD]: dashboardRequestNotReportingBatteriesCard,
  [Types.DASHBOARD_RESPONSE_NOT_REPORTING_BATTERIES_CARD]: dashboardResponseNotReportingBatteriesCard,
  [Types.DASHBOARD_REQUEST_DELETE_BATTERIES]: dashboardRequestDeleteBatteries,
  [Types.DASHBOARD_REQUEST_DELETE_BATTERIES_NOT_REPORTING]: dashboardRequestDeleteBatteriesNotReporting,
  [Types.DASHBOARD_RESPONSE_DELETE_BATTERIES]: dashboardResponseDeleteBatteries,
  [Types.DASHBOARD_RESPONSE_DELETE_BATTERIES_NOT_REPORTING]: dashboardResponseDeleteBatteriesNotReporting,
  [Types.DASHBOARD_REQUEST_ADD_BATTERIES]: dashboardRequestAddBatteries,
  [Types.DASHBOARD_RESPONSE_ADD_BATTERIES]: dashboardResponseAddBatteries,
  [Types.DASHBOARD_REQUEST_DELETED_BATTERIES]: dashboardRequestDeletedBatteries,
  [Types.DASHBOARD_REQUEST_DELETED_BATTERIES]: dashboardResetDataDeletedBatteries,
  [Types.DASHBOARD_RESPONSE_DELETED_BATTERIES]: dashboardResponseDeletedBatteries,
  [Types.DASHBOARD_REQUEST_DELETED_BATTERIES_NEXT_PAGE]: dashboardRequestDeletedBatteriesNextPage,
  [Types.DASHBOARD_RESPONSE_DELETED_BATTERIES_NEXT_PAGE]: dashboardResponseDeletedBatteriesNextPage,
  [Types.DASHBOARD_REQUEST_DELETED_BATTERIES_CARD]: dashboardRequestDeletedBatteriesCard,
  [Types.DASHBOARD_RESPONSE_DELETED_BATTERIES]: dashboardResponseDeletedBatteries,
  [Types.DASHBOARD_RESPONSE_DELETED_BATTERIES_CARD]: dashboardResponseDeletedBatteriesCard,
  [Types.DASHBOARD_REQUEST_APPLICATION_USAGE_CARD]: dashboardRequestApplicationUsageCard,
  [Types.DASHBOARD_RESPONSE_APPLICATION_USAGE_CARD]: dashboardResponseApplicationUsageCard,
  [Types.DASHBOARD_REQUEST_NETWORK_IO_CARD]: dashboardRequestNetworkIOCard,
  [Types.DASHBOARD_RESPONSE_NETWORK_IO_CARD]: dashboardResponseNetworkIOCard,
  [Types.DASHBOARD_REQUEST_APPLICATION_USAGE_DETAILS]: dashboardRequestApplicationUsageDetails,
  [Types.DASHBOARD_REQUEST_APPLICATION_USAGE_DETAILS_NEXT_PAGE]: dashboardRequestApplicationUsageDetailsNextPage,
  [Types.DASHBOARD_RESPONSE_APPLICATION_USAGE_DETAILS]: dashboardResponseApplicationUsageDetails,
  [Types.DASHBOARD_RESPONSE_APPLICATION_USAGE_DETAILS_NEXT_PAGE]: dashboardResponseApplicationUsageDetailsNextPage,
  [Types.DASHBOARD_REQUEST_APPLICATION_USAGE_CARD_NEXT_PAGE]: dashboardRequestApplicationUsageCardNextPage,
  [Types.DASHBOARD_RESPONSE_APPLICATION_USAGE_CARD_NEXT_PAGE]: dashboardResponseApplicationUsageCardNextPage,
  [Types.DASHBOARD_REQUEST_NETWORK_IO_NEXT_PAGE]: dashboardRequestNetworkIONextPage,
  [Types.DASHBOARD_RESPONSE_NETWORK_IO_NEXT_PAGE]: dashboardResponseNetworkIONextPage,
  [Types.DASHBOARD_DOCKING_TIME_HARD_USE_DEVICES_CARD]: dashboardDockingTimeHardUseDevicesCard,
  [Types.DASHBOARD_REQUEST_HARD_USE_DEVICES_DETAILS]: dashboardRequestHardUseDevicesDetails,
  [Types.DASHBOARD_RESPONSE_HARD_USE_DEVICES_DETAILS]: dashboardResponseHardUseDevicesDetails,
  [Types.DASHBOARD_GENERIC_RESPONSE_HARD_USE_DEVICES_DETAILS]: dashboardGenericResponseHardUseDevicesDetails,
  [Types.DASHBOARD_REQUEST_LOST_AND_FOUND]: dashboardRequestLostAndFound,
  [Types.DASHBOARD_RESPONSE_LOST_AND_FOUND]: dashboardResponseLostAndFound,
  [Types.DASHBOARD_REQUEST_LOST_AND_FOUND_DETAILS]: dashboardRequestLostAndFoundDetails,
  [Types.DASHBOARD_RESET_DATA_LOST_AND_FOUND_DETAILS]: dashboardResetDataLostAndFoundDetails,
  [Types.DASHBOARD_RESPONSE_LOST_AND_FOUND_DETAILS]: dashboardResponseLostAndFoundDetails,
  [Types.DASHBOARD_REQUEST_APPLICATION_DISRUPTIONS]: dashboardRequestApplicationDisruptions,
  [Types.DASHBOARD_RESPONSE_APPLICATION_DISRUPTIONS]: dashboardResponseApplicationDisruptions,
  [Types.DASHBOARD_RESET_DATA_APPLICATION_DISRUPTIONS_DETAILS]: dashboardResetDataApplicationDisruptionsDetails,
  [Types.DASHBOARD_REQUEST_APPLICATION_DISRUPTIONS_DETAILS]: dashboardRequestApplicationDisruptionsDetails,
  [Types.DASHBOARD_RESPONSE_APPLICATION_DISRUPTIONS_DETAILS]: dashboardResponseApplicationDisruptionsDetails,
  [Types.DASHBOARD_REQUEST_APPLICATION_DISRUPTIONS_DETAILS_NEXT_PAGE]: dashboardRequestApplicationDisruptionsDetailsNextPage,
  [Types.DASHBOARD_RESPONSE_APPLICATION_DISRUPTIONS_DETAILS_NEXT_PAGE]: dashboardResponseApplicationDisruptionsDetailsNextPage,
  [Types.DASHBOARD_REQUEST_APPLICATION_DISRUPTIONS_BY_DEVICE]: dashboardRequestApplicationDisruptionsByDevice,
  [Types.DASHBOARD_RESPONSE_APPLICATION_DISRUPTIONS_BY_DEVICE]: dashboardResponseApplicationDisruptionsByDevice,
  [Types.DASHBOARD_REQUEST_APPLICATION_DISRUPTIONS_BY_APP]: dashboardRequestApplicationDisruptionsByApp,
  [Types.DASHBOARD_RESPONSE_APPLICATION_DISRUPTIONS_BY_APP]: dashboardResponseApplicationDisruptionsByApp,
  [Types.DASHBOARD_REQUEST_APPLICATION_DISRUPTIONS_BY_APP_DETAILS]: dashboardRequestApplicationDisruptionsByAppDetails,
  [Types.DASHBOARD_RESPONSE_APPLICATION_DISRUPTIONS_BY_APP_DETAILS]: dashboardResponseApplicationDisruptionsByAppDetails,
  [Types.DASHBOARD_REQUEST_APPLICATION_DISRUPTIONS_BY_APP_DETAILS_NEXT_PAGE]: dashboardRequestApplicationDisruptionsByAppDetailsNextPage,
  [Types.DASHBOARD_RESPONSE_APPLICATION_DISRUPTIONS_BY_APP_DETAILS_NEXT_PAGE]: dashboardResponseApplicationDisruptionsByAppDetailsNextPage,
  [Types.DASHBOARD_RESET_DATA_APPLICATION_DISRUPTIONS_BY_APP_DETAILS]: dashboardResetDataApplicationDisruptionsByAppDetails,
});
