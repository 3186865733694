/* eslint-disable complexity */
import { Button, Modal } from '@scuf/common'
import memoize from 'lodash/memoize'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Form } from 'redux-form'

import FormDatePicker from 'Components/FormComponents/FormDatePicker'
import FormRadioSelection from 'Components/FormComponents/FormRadioSelection'

import { isHexadecimal, isMaxCharacters, isRequired, isSelectRequired, noSpecialCharacters } from '../../Forms/Validators'
import FormInput from '../FormComponents/FormInput'
import SelectWrapper from '../Location/components/form/components/form-building/components/select-wrapper'
import FileDropWrapper from './component/FileDropWrapper'
import { ACCEPTED_TYPES, DEVICE_TYPE } from './upload-asset-modal.constants'
import { getOsVersion, useDeviceModels } from './upload-asset-modal.hook'
import { ControlContainer, ControlRow, FormTextArea, ReleaseNoteContainer, StyledTitle, UpdateName, FieldStyle} from './upload-asset-modal.styles'

const isMaxCharacters50 = isMaxCharacters(50)
const isMaxCharacters32 = isMaxCharacters(32)
const isMaxCharacters1024 = isMaxCharacters(1024)

const fileValidators = memoize(
  file => file ? '' : 'File required'
)
const flagChecksum = false
const exampleVersion = 'Example: 86.00.19.1480'
const fullUpdate = 'FOTA-application/x-hon-ota-full'
const incrementalUpdate = 'FOTA-application/x-hon-ota-incr'
const commonESPackage = 'FOTA-application/x-hon-ota-commones'
var acceptedTypes = []
const UploadAssetModal = ({
  t,
  modalClose,
  handleSubmit,
  submit,
  editSubmit,
  deviceType,
  loadingFile,
  loadingHash,
  loadedPercent,
  onFileChange,
  fileType,
  updateNameUpload,
  uploadSoftwareFile,
  canAssetRobot,
  isEditMode,
  selectedUpdates,
  isHoneywellUpdate,
  hasPackageCarSubscribedFeature
}) => {
  if (isEditMode) {
    deviceType = selectedUpdates.compatibleDeviceType
    fileType = selectedUpdates.type
  } else {
    selectedUpdates = {}
  }
  const DEVICE_TYPE_WITH_RFID = useMemo(() => hasPackageCarSubscribedFeature ?[...DEVICE_TYPE,
    {
      text: 'RFID Readers',
      value: 'rfidreader'
    }] : DEVICE_TYPE,[hasPackageCarSubscribedFeature])

  const deviceTypeCollection = useMemo(() =>
  canAssetRobot
    ? [...DEVICE_TYPE_WITH_RFID,
      {
        text: 'Robot',
        value: 'robot'
      }]
    : DEVICE_TYPE_WITH_RFID, [canAssetRobot])

  const [compatibleDeviceModels, setDeviceModels] = useState(selectedUpdates.compatibleDeviceModels)
  const [description, setUpdateName] = useState(selectedUpdates.name)
  const [version, setVersion] = useState(selectedUpdates.version)
  const [compatibleVersions, setCompatibleVersion] = useState(isEditMode ? selectedUpdates.compatibleVersions.length > 0 ? selectedUpdates.compatibleVersions[0] : '' : '')
  const [type, setType] = useState(selectedUpdates.type)
  const [compatibleDeviceType, setCompatibleDeviceType] = useState(deviceType)
  const [errorName, setErrorName] = useState('')
  const [id, setId] = useState(selectedUpdates.id)
  const { models, fileTypes, extensions } = useDeviceModels(deviceType === '' ? compatibleDeviceType : deviceType, fileType === '' ? type : fileType)
  const [errorCompatibleVersion, setErrorCompatibleVersion] = useState('')
  const [radioButtonDisabled, setRadioButtonDisabled] = useState(true)
  const [supportedDevices, setSupportedDevices] = useState([])

  const getSupportedDevice = async () => {
    const data = await getOsVersion()
    setSupportedDevices(data)
  }

  useEffect(() => {
    if (isHoneywellUpdate) {
      getSupportedDevice()
    }
  }, [])

  if (extensions && !Array.isArray(extensions)) {
    acceptedTypes=extensions.extensions.split(",")
  }

  function onChangeName (val) {
    setUpdateName(val)
    setErrorName('')
    if (!val) {
      setErrorName('FormInput_RequiredField')
    }
  }

  function onChangeCompatibleVersion (val) {
    setCompatibleVersion(val)
    setErrorCompatibleVersion('')
    if (type === incrementalUpdate) {
      const isR = isRequired(val)
      const noSpecial = noSpecialCharacters(val)
      const max50 = isMaxCharacters50(val)
      setErrorCompatibleVersion(isR || noSpecial || max50)
    }
  }

  function onEditSubmit () {
    if (!description || errorCompatibleVersion || errorName) {
      return
    }
    editSubmit({
      version,
      description,
      compatibleDeviceModels,
      compatibleVersions: [compatibleVersions]
    }, id)
  }

  useEffect(() => {
    if (deviceType === 'mobilecomputer' && (fileType === 'FOTA-application/x-hon-ota-full' || fileType === 'FOTA-application/x-hon-ota-incr')) {
      setRadioButtonDisabled(false)
    } else {
      setRadioButtonDisabled(true)
    }
  }, [deviceType, fileType])

  useEffect(()=>{
    acceptedTypes=[]
  },[])

  return (
    <Form
    onSubmit={handleSubmit}
    >
      <Modal.Header>
        <StyledTitle>{!isEditMode ? t('Upload asset') : 'Edit Asset'}</StyledTitle>
      </Modal.Header>
      <Modal.Content style={{ overflowY: 'scroll', scrollBehavior: 'auto', paddingRight: '10px'}}>
        <ControlRow>
          <ControlContainer marginBotton='1rem'>
            {isEditMode ? <FieldStyle
              name='compatibleDeviceType'
              component={SelectWrapper}
              input={isEditMode ? { value: compatibleDeviceType } : { onChange: setCompatibleDeviceType, value: compatibleDeviceType }}
              disabled={loadingFile || isEditMode}
              props={{ padding: '0' }}
              label={t('Device type')}
              options={deviceTypeCollection}
              fluid
              validate={!isEditMode ? [isSelectRequired] : []}
              reserveSpace={false}
              /> :
            <FieldStyle
                name='compatibleDeviceType'
                component={SelectWrapper}
                disabled={loadingFile || isEditMode}
                props={{ padding: '0' }}
                label={t('Device type')}
                options={deviceTypeCollection}
                fluid
                validate={!isEditMode ? [isSelectRequired] : []}
                reserveSpace={false}
            />
            }
          </ControlContainer>
          <ControlContainer marginBotton='0.5rem'>
            {isEditMode ? <FieldStyle
              component={SelectWrapper}
              disabled={!models.length || loadingFile}
              input={{ value: compatibleDeviceModels, onChange: setDeviceModels }}
              fluid
              indicator='optional'
              label={t('Device models')}
              multiple={true}
              name='compatibleDeviceModels'
              options={models}
              padding='0'
              search={true}
              reserveSpace={false}
            /> :
              <FieldStyle
                component={SelectWrapper}
                disabled={!models.length || loadingFile}
                fluid
                indicator='optional'
                label={t('Device models')}
                multiple={true}
                name='compatibleDeviceModels'
                options={models}
                padding='0'
                search={true}
                reserveSpace={false}
              />
            }
          </ControlContainer>
        </ControlRow>
        <ControlRow>
          <ControlContainer marginBotton='0.5rem'>
            {isEditMode ? <FieldStyle
              component={SelectWrapper}
              input={isEditMode ? { value: type } : { onChange: setType, value: type }}
              disabled={!fileTypes.length || loadingFile || isEditMode}
              fluid
              label={t('File type')}
              name='type'
              options={fileTypes}
              props={{ padding: '0' }}
              validate={!isEditMode ? [isSelectRequired] : []}
              reserveSpace={false}
            /> : <FieldStyle
              component={SelectWrapper}
              disabled={!fileTypes.length || loadingFile || isEditMode}
              fluid
              label={t('File type')}
              name='type'
              options={fileTypes}
              props={{ padding: '0' }}
              validate={!isEditMode ? [isSelectRequired] : []}
              reserveSpace={false}
            /> }
          </ControlContainer>
          <ControlContainer marginBotton='0.5rem'>
            {isEditMode ? <UpdateName
              component={FormInput}
              disabled={loadingFile}
              fluid
              label={t('Update name')}
              name='description'
              input={isEditMode ? { value: description, onChange: onChangeName } : {}}
              meta={isEditMode ? { error: errorName, touched: true } : {}}
              validate={[
                isRequired,
                noSpecialCharacters,
                isMaxCharacters50
              ]}
            />
              : <UpdateName
                component={FormInput}
                disabled={loadingFile}
                fluid
                label={t('Update name')}
                name='description'
                validate={[
                  isRequired,
                  noSpecialCharacters,
                  isMaxCharacters50
                ]}
              />}
          </ControlContainer>
        </ControlRow>
        <ControlRow>
          <ControlContainer marginBotton='0.5rem'>
            {isEditMode ? <UpdateName
              component={FormInput}
              disabled={loadingFile}
              fluid
              indicator={fileType === fullUpdate || fileType === commonESPackage ? null : 'optional'}
              label={t('Version')}
              name='version'
              placeholder={fileType === incrementalUpdate || fileType === fullUpdate ? exampleVersion : null}
              input={isEditMode ? { value: version, helperText: version === '' ? 'If left blank the version will be left unchanged.' : '', onChange: setVersion } : {}}
              validate={fileType === fullUpdate ? [isRequired, noSpecialCharacters, isMaxCharacters32] : [noSpecialCharacters, isMaxCharacters32]}
            />
              : <UpdateName
                component={FormInput}
                disabled={loadingFile}
                fluid
                indicator={fileType === fullUpdate || fileType === commonESPackage ? null : 'optional'}
                label={t('Version')}
                name='version'
                placeholder={fileType === incrementalUpdate || fileType === fullUpdate ? exampleVersion : null}
                validate={fileType === fullUpdate ? [isRequired, noSpecialCharacters, isMaxCharacters32] : [noSpecialCharacters, isMaxCharacters32]}
              />
            }
          </ControlContainer>
          <ControlContainer>
            {isEditMode ? <UpdateName
              label={t('Compatible version')}
              name='compatibleVersions'
              placeholder={fileType === incrementalUpdate || fileType === fullUpdate ? exampleVersion : null}
              component={FormInput}
              indicator={fileType !== incrementalUpdate && 'optional'}
              disabled={loadingFile}
              fluid
              meta={isEditMode ? { error: errorCompatibleVersion, touched: true } : {}}
              input={isEditMode ? { value: compatibleVersions, onChange: onChangeCompatibleVersion } : {}}
              validate={fileType === incrementalUpdate ? [isRequired, noSpecialCharacters, isMaxCharacters50] : noSpecialCharacters}
            /> : <UpdateName
              label={t('Compatible version')}
              name='compatibleVersions'
              placeholder={fileType === incrementalUpdate || fileType === fullUpdate ? exampleVersion : null}
              component={FormInput}
              indicator={fileType !== incrementalUpdate && 'optional'}
              disabled={loadingFile}
              fluid
              validate={fileType === incrementalUpdate ? [isRequired, noSpecialCharacters, isMaxCharacters50] : noSpecialCharacters}
            />  }
          </ControlContainer>
        </ControlRow>
        {flagChecksum
          ? <ControlRow>
            <ControlContainer>
              <FieldStyle
                name='checksum'
                component={FormInput}
                disabled={loadingFile}
                label={t('SHA1 Checksum')}
                validate={[
                  isHexadecimal
                ]}
                fluid
              />
            </ControlContainer>
          </ControlRow>
          : null}
        {isHoneywellUpdate &&
          <Fragment name='properties'>
            <ControlRow>
              <ControlContainer>
                <FieldStyle
                  name='android'
                  component={SelectWrapper}
                  disabled={radioButtonDisabled}
                  props={{ padding: '0' }}
                  label={t('Android')}
                  options={supportedDevices}
                  fluid
                />
              </ControlContainer>
              <ControlContainer marginBotton='0.5rem'>
                <FieldStyle
                  name='releaseDate'
                  component={FormDatePicker}
                  disablePast={false}
                  label={t('releaseDate')}
                  highlightWeekends={true}
                  type='date'
                  validate={[isRequired]}
                />
              </ControlContainer>
            </ControlRow>
            <ControlRow>
              <ControlContainer>
                <StyledTitle>{t('GMS')}\{t('NON-GMS')}</StyledTitle>
                <FieldStyle
                  name='gms'
                  component={FormRadioSelection}
                  options={[{
                    label: t('GMS'),
                    value: true
                  }, {
                    label: t('NON-GMS'),
                    value: false
                  }]}
                  disabled={radioButtonDisabled}
                />
              </ControlContainer>
              <ControlContainer>
                <StyledTitle>{t('Licensed')}</StyledTitle>
                <FieldStyle
                  name='licensed'
                  component={FormRadioSelection}
                  options={[{
                    label: t('YES'),
                    value: true
                  }, {
                    label: t('NO'),
                    value: false
                  }]}
                  disabled={radioButtonDisabled}
                />
              </ControlContainer>
            </ControlRow>
            <ControlRow>
              <ReleaseNoteContainer>
                <FieldStyle
                  component={FormTextArea}
                  fluid={true}
                  label={t('Release Note')}
                  name='releaseNote'
                  reserveSpace={true}
                  validate={isMaxCharacters1024}
                />
              </ReleaseNoteContainer>
            </ControlRow>
          </Fragment>
        }
        {!isEditMode
          ? <FieldStyle
            component={FileDropWrapper}
            label={t('Upload Asset')}
            name='file'
            onChange={(_, file) => onFileChange(file)}
            props={{
              loadedPercent,
              acceptedTypes: acceptedTypes
            }}
            validate={[
              fileValidators
            ]}
            loadingFile={loadingFile || loadingHash}
            loadingHash={loadingHash}
            fileType={fileType}
          />
          : null}
      </Modal.Content>
      <Modal.Footer>
        <Button
          type='secondary'
          content='Cancel'
          disabled={loadingFile}
          onClick={modalClose}
        />
        <Button
          type='primary'
          content={!isEditMode ? 'Upload' : 'Save'}
          disabled={loadingFile || errorName.length > 0 || deviceType === '' || fileType === '' || updateNameUpload === '' || uploadSoftwareFile === '' || uploadSoftwareFile === null}
          onClick={!isEditMode ? submit : onEditSubmit}
        />
      </Modal.Footer>
    </Form>
  )
}

UploadAssetModal.defaultProps = {
  t: l => l
}

export default UploadAssetModal
