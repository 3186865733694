
import React, { Component, Fragment } from 'react'
import { Button, InputLabel, Icon, Radio, Modal } from '@scuf/common'
import { isEmpty } from 'ramda'
import { noSpecialCharactersWithoutSpaces } from '../../../../Forms/Validators'
import {
  columnsValidations,
  ERRORS,
  downloadProvisonResults
} from './form-devices.helpers'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { SuccessToast, ErrorToast } from '../../../../Themes/ScufStyledComponents'
import ErrorTooltip from './components/error-tooltip/ErrorTooltip'
import CsvFileDrop from '../../../CsvFileDrop'
import PropTypes from 'prop-types'
import devicesSampleData from './form-device-sample-data'
import { upperFirst } from 'lodash'
import get from 'lodash/get'
import {
  Container,
  Col,
  Row,
  PaddedContainer,
  BoldError,
  ProvisionProgressBar,
  LineProgressBar,
  StyledSelect,
  TableTitle,
  StyledSelectDropdown,
  PageSubTitle,
  HeaderWrap,
  StyledH2,
  ParagraphModal
} from './form-devices.styles'

import SerialNumber from './components/serial-number';
import DevicesTable from './components/devices-table/DevicesTable';
import { TypeApi } from '../../../../Services'
import { getDevices, mathedDeviceType, mathedType } from './form-devices.helpers'

const NOT_PROVISIONED = 'Not provisioned'
const PENDING = 'Pending'
const PROVISIONING = 'Provisioning'
const PROVISIONED = 'Provisioned'

class FormUploadDevices extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedDeviceType: '',
      selectedDeviceModel: '',
      serialNumber: '',
      selectedSite: '',
      devicesAddedToList: [],
      devicesAddedToListKeys: {},
      csvLoaded: false,
      loadingCsv: false,
      addedManually: false,
      maxNumberOfDevices: 1000,
      provision: '',
      dataSwitchMode: false,
      deviceTypeLists: '',
      deviceModelLists: '',
      isClear: false
    };
    this.addSerialNumberToList = this.addSerialNumberToList.bind(this)
    this.changeResults = this.changeResults.bind(this)
    this.actionsRenderer = this.actionsRenderer.bind(this)
    this.clean = this.clean.bind(this)
    this.typeRenderer = this.typeRenderer.bind(this)
    this.updateSerialNumber = this.updateSerialNumber.bind(this)
    this.changeSite = this.changeSite.bind(this)
    this.provisionSelect = this.provisionSelect.bind(this)
    this.getMatchedId = this.getMatchedId.bind(this)
    this.getMatchedsiteName = this.getMatchedsiteName.bind(this)
    this.onSubmitDetails = this.onSubmitDetails.bind(this)
    this.getModels = this.getModels.bind(this)
    this.getProvisioningStatus = this.getProvisioningStatus.bind(this)
    this.clearStatus = this.clearStatus.bind(this)
    this.getMatchedModelName = this.getMatchedModelName.bind(this)
    this.getMatchedModelDisplayName = this.getMatchedModelDisplayName.bind(this)
  }

  componentDidMount() {
    this.props.getDevices()
    if (isEmpty(this.props.sites)) {
      this.props.getSites()
    }
    this.getModels()
    this.getProvisioningStatus()
  }

  getProvisioningStatus() {
    const getStatus = async () => {
      const response = await TypeApi.getProvisioningStatus()
      if (response.ok) {

        const data = {
          data: response.data.provisionedList
        }
        this.setState({
          isClear: response.data.isRemovable
        })

        this.props.provisionStatusUpdate(data)
      } else {
        this.props.cleanResults()
      }
    }
    getStatus()
  }

  async getModels() {
    const getTypesModels = await getDevices()
    this.setState({
      deviceTypeLists: getTypesModels.deviceTypeLists,
      deviceModelLists: getTypesModels.deviceModelLists
    })
  }

  beforeSubmitValidations() {
    const { selectedSite } = this.state
    const { sitesList, sites } = this.props
    if (!selectedSite && this.state.provision === 'manual') {
      toast(<ErrorToast message={'Select a Site'} />)
      return false
    } else if (sitesList.filter(site => site.value === selectedSite[0]).length === 0 && this.state.provision === 'manual') {
      toast(<ErrorToast message='Selected site is not valid' />, { containerId: 'MODAL_TOAST_CONTAINER' })
      return false
    }
    return true
  }

  parseProvisionResultsToCsvFormat() {
    const header = ['Device Type', 'Device Model', 'Serial Number', 'Alias', 'Site', 'Status', 'Message']
    let csvText = header.join(',') + '\n'
    this.props.devicesToProvision.forEach(d => {
      const rowData = []
      header.forEach(column => {
        let dColumn = ''
        if (column === 'Device Type') {
          dColumn = d['type'] === 'mobilecomputer' ? 'Mobile Computer' : d['type'] === 'printer' ? 'Industrial/Desktop Printer' : d['type'];
        } else if (column === 'Site') {
          dColumn = this.getMatchedsiteName(d['siteId'])
        } else if (column === 'Device Model') {
          dColumn = this.getMatchedModelName(d['model'])
        } else if (column === 'Serial Number') {
          dColumn = d['serial']
        } else if (column === 'Alias') {
          dColumn = d['alias']
        } else if (column === 'Status') {
          dColumn = d['status']
        } else {
          dColumn = d['message']
        }
        rowData.push(dColumn)
      })
      csvText += rowData.join(',') + '\n'
    })
    return csvText
  }

  getMatchedId(site) {
    const { sitesList } = this.props
    var matchedItem = sitesList.filter(item => {
      return item.text.trim() === site.trim()
    })
    return matchedItem.length ? matchedItem[0].value : '';
  }

  getMatchedModelDisplayName(model) {
    const { deviceModelLists } = this.state
    if (deviceModelLists.length > 0) {
      var matchedItem = deviceModelLists.filter(modelItem => {
        return modelItem.text.toUpperCase() === model.toUpperCase()
      })
      return matchedItem.length ? matchedItem[0].name : '';
    }
  }

  getMatchedModelName(model) {
    const { deviceModelLists } = this.state
    if (deviceModelLists.length > 0) {
      var matchedItem = deviceModelLists.filter(modelItem => {
        return modelItem.name === model
      })
      return matchedItem.length ? matchedItem[0].text : '';
    }
  }

  getMatchedsiteName(siteId) {
    const { sitesList } = this.props
    var matchedItem = sitesList.filter(item => {
      return item.value === siteId
    })
    return matchedItem.length ? matchedItem[0].text : '';
  }

  addSerialNumberToList(serialNumber) {
    const {
      devicesAddedToListKeys,
      devicesAddedToList,
      selectedDeviceModel,
      selectedDeviceType,
      selectedSite
    } = this.state
    const { deviceTypesByText } = this.props
    const newSerials = []
    let manually = false

    if (Array.isArray(serialNumber)) {
      serialNumber.forEach(deviceData => {
        let newDeviceData = {}
        const key = deviceData['Device Model'] + deviceData['Serial Number']
        if (!devicesAddedToListKeys[key]) {
          devicesAddedToListKeys[key] = true
          newDeviceData.key = key
          newDeviceData.alias = deviceData.Alias || key
          newDeviceData.type = deviceTypesByText[deviceData['Device Type']] || deviceData['Device Type']
          newDeviceData.model = deviceData['Device Model'] || ''
          newDeviceData.siteId = deviceData.Site || ''
          newDeviceData.serial = deviceData['Serial Number']
          newSerials.push(newDeviceData)
        }
      })
    } else {
      const key = selectedDeviceModel + serialNumber
      if (!devicesAddedToListKeys[key] && serialNumber) {
        manually = true
        devicesAddedToListKeys[key] = true
        newSerials.push({
          siteId: selectedSite[1],
          model: selectedDeviceModel,
          type: mathedType(selectedDeviceType),
          alias: key,
          serial: serialNumber,
          key
        })
      }
    }
    this.validateLinesAndFields(newSerials)
    const sortedSerialsbyError = [...devicesAddedToList, ...newSerials]
    // sortedSerialsbyError.sort((a, b) => a.status < b.status ? -1 : 1)
    this.setState({
      devicesAddedToList: sortedSerialsbyError,
      devicesAddedToListKeys,
      selectedDeviceModel: '',
      serialNumber: '',
      addedManually: manually
    })
  }

  validateLinesAndFields(newSerials) {

    const { deviceModelsById, deviceTypesById, t, sites } = this.props
    const deviceTypeListsCheck = this.state.deviceTypeLists.reduce(
      (obj, item) => Object.assign(obj, { [item.value]: item.text }), {})
    const deviceModelListsCheck = this.state.deviceModelLists.reduce(
      (obj, item) => Object.assign(obj, { [item.value]: true }), {})
    const deviceSitesCheck = this.props.sites.reduce(
      (obj, item) => Object.assign(obj, { [item.title.trim()]: true }), {})

    newSerials.forEach(deviceData => {
      deviceData.errors = {}
      Object.keys(columnsValidations).forEach(column => (deviceData.errors[column] = []))
      // Regex rules
      Object.keys(columnsValidations).forEach(column => {
        columnsValidations[column].forEach(rule => {
          const message = rule.validation(deviceData[column]) && t(rule.validation(deviceData[column]), rule.params);
          if (message) { // fails
            deviceData.errors[column].push(message)
          }
        })
      })
      // Aditional custom rules
      // Device model exist
      if (!deviceModelListsCheck[deviceData.model]) {
        deviceData.errors.model.push(t(ERRORS.MODELNOTFOUND))
      }
      // Device type exist
      let deviceType = ''
      if (deviceData.type === 'printer' || deviceData.type === 'Industrial/Desktop Printer') {
        deviceType = 'printer'
      } else if (deviceData.type === 'mobilecomputer' || deviceData.type === 'Mobile Computer') {
        deviceType = 'mobilecomputer'
      }

      deviceTypeListsCheck['printer'] = 'Industrial/Desktop Printer';

      if (!deviceTypeListsCheck[deviceType] || (!deviceType == 'printer')) {
        deviceData.errors.type.push(t(ERRORS.TYPENOTFOUND))
      }

      // Site
      if (!deviceSitesCheck[deviceData.siteId.trim()]) {
        deviceData.errors.siteId.push(t(ERRORS.SITENOTFOUND))
      }
      // Alias
      if (deviceData.alias === '') {
        deviceData.errors.alias.push(t(ERRORS.ALIASNOTFOUND))
      }

      // Serial Number
      if (deviceData.serial === '') {
        deviceData.errors.serial.push(t(ERRORS.SERIALNOTFOUND))
      }

      const fieldsWithErrors = Object.keys(deviceData.errors)
        .filter(col => deviceData.errors[col].length > 0)
        .map(field => upperFirst(field))

      const rowData = []
      fieldsWithErrors.forEach(column => {
        let columnName = ''
        if (column === 'SiteId') {
          columnName = 'Site'
        } else if (column === 'Model') {
          columnName = 'Device Model'
        } else if (column === 'Type') {
          columnName = 'Device Type'
        } else {
          columnName = column
        }
        rowData.push(columnName)
      })
      deviceData.status = rowData.length > 0 ? 'Error in columns: ' + rowData.join(', ') : 'Ready'
    })
  }

  changeResults(term) {
    const filteredResults = this.props.sites.filter(item => get(item, 'title', '').toLowerCase().includes((term || '').toLowerCase()))
    this.setState({ results: filteredResults, selectedSite: [term, ''] })
  }

  changeSite(e) {
    const filtereSites = this.props.sites.filter(item => get(item, 'ocupation', '').toLowerCase().includes((e || '').toLowerCase()))
    this.setState({ selectedSite: [e, filtereSites[0].title] })
  }

  onSubmitDetails() {
    if (this.beforeSubmitValidations()) {
      const { devicesAddedToList } = this.state
      let revisedDeviceList = [...devicesAddedToList]
      revisedDeviceList = revisedDeviceList.map(d => ({ ...d, type: mathedDeviceType(d.type), siteId: this.getMatchedId(d.siteId), model: this.getMatchedModelDisplayName(d.model) }))
      toast(<SuccessToast message='Provision sent' />, { containerId: 'MODAL_TOAST_CONTAINER' })
      this.props.onSubmit(revisedDeviceList, this.props.sites.length ? this.props.sites[0].ocupation : '')
    }
  }

  provisionSelect(value) {
    if ((this.state.provision === 'bulk' || this.state.provision === 'manual') && this.state.devicesAddedToList.length > 0) {
      this.setState({
        dataSwitchMode: true
      })
    } else {
      this.setState({ provision: value })
    }
  }

  editData(newVal, cellData) {
    const data = this.state.devicesAddedToList
    data[cellData.rowIndex][cellData.field] = newVal
    this.validateLinesAndFields(data)
    this.setState({ devicesAddedToList: data })
  }

  typeRenderer({ field, value, rowData }) {
    const { errors } = rowData
    const { deviceIcon } = this.props
    let deviceType = ''
    if (value === 'printer' || value === 'Industrial/Desktop Printer') {
      deviceType = 'printer'
    } else if (value === 'mobilecomputer' || value === 'Mobile Computer') {
      deviceType = 'mobilecomputer'
    }
    return <span>
      {
        errors && errors[field]?.length > 0 ?
          <ErrorTooltip component={errors[field].join(', ')} icon right />
          : <Icon style={{ fontSize: '5rem' }} size='medium' name={deviceIcon[deviceType]} />
      }
    </span>
  }

  devicesWithErrors() {
    return this.state.devicesAddedToList.filter(d => d.status !== 'Ready').length
  }

  actionsRenderer({ rowData: { key, status } }) {
    let component
    if (/^provisioned$/i.test(status)) {
      component = <span><Icon name='check' size='medium' color='green' /> </span>
    } else {
      if ((/^provisioning$/i.test(status)) || (/^Pending$/i.test(status))) {
        component = <LineProgressBar size='xsmall' style={{ width: '100%', marginTop: '10px' }} />
      } else if (/^not provisioned$/i.test(status)) {
        component = <span><Icon name='badge-warning' size='medium' color='red' /></span>
      } else {
        component =
          <span>
            <Icon className='Icon' style={{ fontSize: '5rem' }} name='delete' size='medium' onClick={() => {
              this.setState({ loadingCsv: true })
              const { devicesAddedToListKeys, devicesAddedToList } = this.state
              if (devicesAddedToList.length === 0) this.setState({ csvLoaded: false })
              devicesAddedToListKeys[key] = false
              this.setState({
                devicesAddedToList: devicesAddedToList.filter(e => e.key !== key),
                devicesAddedToListKeys: devicesAddedToListKeys,
                loadingCsv: false
              })
            }} />
          </span>
      }
    }
    return component
  }

  clean() {
    this.setState({
      devicesAddedToList: [],
      devicesAddedToListKeys: {},
      csvLoaded: false,
      loadingCsv: false,
      selectedDeviceType: '',
      selectedDeviceModel: '',
      serialNumber: '',
      selectedSite: '',
      isClear: false
    })
    this.props.cleanResults()
  }

  clearStatus() {
    const getStatus = async () => {
      const response = await TypeApi.removeDevices()
      this.clean()
    }
    getStatus()
  }

  countDevicesBystatus(filter) {
    return this.props.devicesToProvision.filter(({ status }) => status.toLowerCase() === filter.toLowerCase()).length
  }

  provisioningPercentage() {
    const { devicesToProvision } = this.props
    return parseInt((devicesToProvision.filter(d => d.status !== PROVISIONING).length * 100) / (devicesToProvision.length || 1))
  }

  updateSerialNumber() {
    const { serialNumber } = this.state
    if (!noSpecialCharactersWithoutSpaces(serialNumber)) {
      this.addSerialNumberToList(serialNumber)
    }
  }

  renderWarnings() {
    const {
      devicesAddedToList,
      maxNumberOfDevices
      	  } = this.state
    const { devicesToProvision, t } = this.props
    return <Row>
      {
        (devicesAddedToList.length || devicesToProvision.length) ?
          <Icon
            name={this.devicesWithErrors() || this.countDevicesBystatus(NOT_PROVISIONED) ? 'badge-warning' : 'check'}
            size='medium'
            style={{ color: this.devicesWithErrors() || this.countDevicesBystatus(NOT_PROVISIONED) ? '#be271c' : '' }} /> : null
      }
      <Col>
        {
          devicesAddedToList.length > maxNumberOfDevices ?
            <span>
              You can`&apos;`t provision more than <BoldError>{maxNumberOfDevices} </BoldError> devices by submission
            </span> : null
        }
        {
          (this.state.provision === 'manual' || this.state.provision === 'bulk') && devicesAddedToList.length && !devicesToProvision.length ?
            <span style={{ paddingTop: '5px' }}>
              {devicesAddedToList.length - this.devicesWithErrors()} {t('devicesProvisionedReady')} - {this.devicesWithErrors()} {t('devicesProvisionedErrors')}
            </span> : null
        }
        {
          devicesToProvision.length ?
            <span>
              <b> {this.countDevicesBystatus(PROVISIONED)} </b>
				  {t('devicesProvisionedSuccesfully')} - 
              <b> {this.countDevicesBystatus(NOT_PROVISIONED)} </b> {t('devicesNotProvisionedSuccesfully')}
            </span> : null
        }
        {
          devicesToProvision.length && this.provisioningPercentage() === 100 ?
            <span> Click <a href={downloadProvisonResults(this.parseProvisionResultsToCsvFormat())} download='results.csv'> here </a> for more details </span> : null
        }
      </Col>
    </Row>
  }

  render() {
    const {
      deviceModels,
      deviceTypes,
      devicesToProvision,
      t,
      sitesList,
      closeModal
    } = this.props

    const {
      devicesAddedToList,
      serialNumber,
      loadingCsv,
      selectedSite,
      csvLoaded,
      results,
      selectedDeviceModel,
      selectedDeviceType,
      addedManually,
      maxNumberOfDevices,
      provision,
      dataSwitchMode,
      deviceTypeLists,
      deviceModelLists,
      isClear } = this.state

    return (<Container>
      <Modal closeIcon={false} closeOnDimmerClick={false} open={dataSwitchMode} size='small'>
        <Modal.Header>
          <HeaderWrap>
            <StyledH2>{t('FormInput_TitleProvisionSwitch')}</StyledH2>
            <ParagraphModal>
              {t('FormInput_DescriptionProvisionSwitch')}
            </ParagraphModal>
          </HeaderWrap>
        </Modal.Header>
        <Modal.Footer>
          <Button
            content='No' onClick={() => {
              this.setState({
                dataSwitchMode: false,
                provision: this.state.provision
              })

            }} size='medium' type='secondary'
          />
          <Button
            content='Yes' onClick={() => {
              let provisionState = this.state.provision === 'manual' ? 'bulk' : 'manual'
              this.setState({
                dataSwitchMode: false,
                provision: provisionState,
                devicesAddedToList: [],
                devicesAddedToListKeys: {},
                csvLoaded: false,
                loadingCsv: false,
                selectedDeviceType: '',
                selectedDeviceModel: '',
                serialNumber: '',
                selectedSite: ''
              })
              this.props.cleanResults()

            }} size='medium' type='primary'
          />
        </Modal.Footer>
      </Modal>
      {
        <PaddedContainer>
          <Row>
            <PageSubTitle>
              {t('provisionAsset')}
            </PageSubTitle>
          </Row>
          <Row>
            <Radio
              label={t('addManually')}
              name='RadioGroup'
              checked={provision === 'manual'}
              onChange={() => this.provisionSelect('manual')}
              disabled={
                this.countDevicesBystatus(PROVISIONING) > 0 ||
                this.countDevicesBystatus(NOT_PROVISIONED) > 0 ||
                this.countDevicesBystatus(PENDING) > 0 ||
                this.countDevicesBystatus(PROVISIONED) > 0
              }
            />
            <Radio
              label={t('importBulk')}
              name='RadioGroup'
              checked={provision === 'bulk'}
              onChange={() => this.provisionSelect('bulk')}
              disabled={
                this.countDevicesBystatus(PROVISIONING) > 0 ||
                this.countDevicesBystatus(NOT_PROVISIONED) > 0 ||
                this.countDevicesBystatus(PENDING) > 0 ||
                this.countDevicesBystatus(PROVISIONED) > 0
              }
            />
          </Row>

          {provision === 'manual' && (<Fragment>
            <Row>
            </Row>
            <Row>
              <Col flex={4} style={{ "zIndex": 9999 }}>
                {(sitesList.length > 0) && <StyledSelectDropdown
                  indicator='required'
                  fluid
                  label={'Site'}
                  onChange={this.changeSite}
                  options={sitesList}
                  reserveSpace={true}
                  search={true}
                  helperText={t('siteHelperText')}
                  value={this.state.selectedSite[0]}
                  placeholder={t('selectSite')}
                />}
              </Col>
            </Row>

            <Row>
              <Col flex={3}>
                {(deviceTypeLists.length > 0) && <StyledSelect
                  indicator='required'
                  label={t('FormInput_DeviceType')}
                  fluid
                  disabled={(csvLoaded && devicesAddedToList.length > 0) || loadingCsv || !selectedSite}
                  id='selectDeviceTypeUploadCsv'
                  placeholder={t('FormInput_SelectDeviceType')}
                  search={true}
                  options={deviceTypeLists}
                  onChange={e => {
                    this.setState({ selectedDeviceType: e })
                  }}
                  value={this.state.selectedDeviceType}
                />}
              </Col>
              <Col flex={3}>
                {(deviceModelLists.length > 0) && <StyledSelect
                  label={t('FormInput_DeviveModel')}
                  fluid
                  disabled={(csvLoaded && devicesAddedToList.length > 0) || loadingCsv || !selectedDeviceType}
                  id='selectDeviceModelUploadCsv'
                  placeholder={t('FormInput_SelectDeviceModel')}
                  options={deviceModelLists.length > 0 ? deviceModelLists.filter(({ type }) => type === selectedDeviceType) : ''}
                  onChange={e => {

                    this.setState({ selectedDeviceModel: e })
                  }
                  }
                  value={selectedDeviceModel}
                  indicator='required'
                  search={true}
                />}
              </Col>
              <Col flex={6}>
                <SerialNumber
                  csvLoaded={csvLoaded}
                  devicesAddedToList={devicesAddedToList}
                  onChange={e => this.setState({ serialNumber: e })}
                  onClick={this.updateSerialNumber}
                  onEnter={this.updateSerialNumber}
                  selectedDeviceModel={selectedDeviceModel}
                  serialNumber={serialNumber}
                  selectedDeviceType={selectedDeviceType}
                  t={t}
                />
              </Col>
            </Row>
          </Fragment>)}

          {provision === 'bulk' && (<Row>
            <TableTitle>{t('FormInput_DevicesToProvision')}</TableTitle>
          </Row>)}

          {provision === 'bulk' && !this.state.csvLoaded && (<Row>
            <CsvFileDrop
              chars={23}
			  placeholder={t('DropFile')} // @ts-ignore
              buttonText={t('BrowseFiles')}
              onLoadFile={() => {
                this.clean()
                this.setState({ loadingCsv: true })
              }}
              onLoadFileSuccess={(lines) => {
                this.addSerialNumberToList(lines)
                this.setState({
                  csvLoaded: true,
                  loadingCsv: false
                })
              }}
              onReset={this.clean}
              disabled={addedManually && devicesAddedToList.length > 0}
              sampleDataLink
              sampleData={devicesSampleData}
              fileHeadersCheck
            />
          </Row>)}

          {(provision === 'bulk' || provision === 'manual' || devicesToProvision.length > 0) &&
            (<Fragment>
              <Row>
                {(this.state.csvLoaded || devicesToProvision.length > 0 || provision === 'manual') && <DevicesTable
                  devicesToProvision={devicesToProvision.map(d => ({ ...d, siteId: this.getMatchedsiteName(d.siteId), model: this.getMatchedModelName(d.model) }))}
                  devicesAddedToList={devicesAddedToList}
                  onEdit={(newData, cellData) => this.editData(newData, cellData)}
                  loadingCsv={loadingCsv}
                  typeRenderer={this.typeRenderer}
                  actionsRenderer={this.actionsRenderer}
                />}
              </Row>
              <Row>
                <ProvisionProgressBar
                  size='xsmall' percent={this.provisioningPercentage()}
                  isvisible={this.countDevicesBystatus(PROVISIONING) > 0 ? 1 : 0}
                />
              </Row>
            </Fragment>)
          }
          <Row>

            {this.renderWarnings()}

          </Row>
          <Row justifyContent='flex-end'>
            {this.state.isClear && <Button
              id='Clear' type='secondary' content={t('clear')} onClick={this.clearStatus} />}
            {(provision === 'bulk' || provision === 'manual') && (<Button disabled={
              !(devicesAddedToList.length || devicesToProvision.length) ||
              this.countDevicesBystatus(PROVISIONING) > 0 ||
              this.countDevicesBystatus(PENDING) > 0 ||
              this.countDevicesBystatus(PROVISIONED) > 0 ||
              this.countDevicesBystatus(NOT_PROVISIONED) > 0
            }
              id='removeall' type='secondary' content={t('remove')} onClick={this.clean} />)}
            {(this.countDevicesBystatus(PROVISIONING) > 0 || this.countDevicesBystatus(PENDING) > 0 ||
              this.countDevicesBystatus(NOT_PROVISIONED) > 0 || this.countDevicesBystatus(PROVISIONED) > 0) && (<Button
                id='Refresh' type='secondary' content={t('refresh')} disabled={this.state.isClear} onClick={this.getProvisioningStatus} />)}

            <Button
              id='Close' type='secondary' content={t('close')} onClick={closeModal} />
            <Button
              disabled={
                this.devicesWithErrors() > 0 ||
                !(devicesAddedToList.length || devicesToProvision.length) ||
                this.countDevicesBystatus(PROVISIONING) > 0 ||
                devicesAddedToList.length > maxNumberOfDevices ||
                this.countDevicesBystatus(NOT_PROVISIONED) > 0 ||
                this.countDevicesBystatus(PENDING) > 0 ||
                this.countDevicesBystatus(PROVISIONED)
              }
              type='primary'
              id='submit'
              content={t('submit')}
              onClick={this.onSubmitDetails}
            />
          </Row>
        </PaddedContainer>
      }
    </Container>)
  }
}

FormUploadDevices.displayName = 'FormUploadDevices'

FormUploadDevices.propTypes = {
  devicesToProvision: PropTypes.array,
  sites: PropTypes.array,
  deviceModelsById: PropTypes.object,
  deviceTypesById: PropTypes.object,
  getSites: PropTypes.func,
  getFamiliesAndModels: PropTypes.func,
  deviceTypesByText: PropTypes.object,
  deviceIcon: PropTypes.object,
  onSubmit: PropTypes.func,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
  provisionStatusUpdate: PropTypes.func
}

FormUploadDevices.defaultProps = {
  deviceModelsById: {},
  deviceTypesById: {},
  devicesAddedToList: [],
  devicesAddedToListKeys: {},
  devicesToProvision: [],
  errors: [],
  getDevices: () => null,
  loading: false,
  sites: [],
  sitesList: [],
}

export default (FormUploadDevices)
