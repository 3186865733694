import React, { PureComponent, Fragment } from 'react'
import { isEmpty } from 'ramda'
import { Modal, Select, Search } from '@scuf/common'

import ErrorComponent from '../../Components/ErrorEvent'
import { CenterCard } from '../../Components/QrSection'
import { ModalTabs} from '../../Components/Tabs'
import Form from './components/form'
import Table from './components/device-form-table'

import {
  TableContainer,
  PaneContainer,
  SuperContainer,
  Title,
  FormRow
} from '../../Themes/ScufStyledComponents'

class FormWrapper extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      filter: ''
    }
  }

  componentDidMount () {
    if (isEmpty(this.props.devices)) {
      setTimeout(() => this.props.getDevices(), 100)
    }
    if (isEmpty(this.props.sites)) {
      this.props.getSites()
    }
  }

  render () {
    const { loading, t, sitesByHierarchy, currentSiteId, firmSetCurrentSite, setCurrentDevice, isDelete } = this.props
    const { filter } = this.state
    return(
      <Fragment>
        <Modal.Header>
          <div style={{ justifyContent: 'space-between', width: '100%' }}>
            { isDelete
              ? <FormRow><Title style={{ flex: 1, height: '48px' }}>{t('DeviceForm_Devices')}</Title></FormRow>
              : <FormRow justifyContent='space-beween'>
                <Title style={{ flex: 1 }}>{t('DeviceForm_Devices')}</Title>
                <FormRow>
                  <Select
                    fluid
                    id='modal-sites-select'
                    placeholder="Filter by Site"
                    options={sitesByHierarchy}
                    value={currentSiteId}
                    onChange={firmSetCurrentSite}
                    className='Side-Margins'
                  />
                  <Search
                    fluid
                    placeholder={t('DeviceForm_Search')}
                    onSearchChange={(filter) => this.setState({ filter: filter })}
                    onResultSelect={(item) => setCurrentDevice(item.id)}
                  />
                </FormRow>
              </FormRow>
            }
            <ModalTabs type={isDelete ? 'delete' : 'edit'} activeIndex={2} />
          </div>
        </Modal.Header>
        <SuperContainer padding={0} backgroundColor='transparent' height='calc(100% - 9em)' >
        { loading || isDelete
          ? <ErrorComponent loading={loading && !isDelete}>
            { isDelete
              ? t('DeviceForm_CannotRemove')
              : t('DeviceForm_Search')
            }
          </ErrorComponent>
          : <Fragment>
              <TableContainer flex={1.5} hoverFlex={1.5} background='transparent'>
                <Table filter={filter} />
              </TableContainer>
              <PaneContainer flex={1} background='transparent'>
                <CenterCard style={{ flexDirection: 'column' }}>
                  <Form />
                </CenterCard>
              </PaneContainer>
            </Fragment>
          }
        </SuperContainer>
      </Fragment>
    )
  }
}

export default FormWrapper
