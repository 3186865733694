import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Search
} from '@scuf/common'

import {
  HeaderContainer,
  Subtitle

} from './manage-tags-modal.styles'
import { StyledModal } from '../Modal/modal.styles'

import Tags from '../Tags'
import { TagsApi } from '../../Services'

const ManageTagsModal = ({
  devices,
  onClose,
  isOpen,
  getTags,
  tags,
  selectedTags,
  updateTags,
  theme,
  t,
  ...rest
}) => { 
  const [devicesTags, setDevicesTags] = useState([])
  const [isEditing, setIsEditing] = useState(false)
  const [isEditTagValidationShowed, setIsEditTagValidationShowed] = useState(false)

  useEffect(() => {
    if (isOpen && devices && devices.length) {
      getTags(devices)
    }
  }, [devices, isOpen])

  return (
    <StyledModal
      closeIcon={true}
      closeOnDocumentClick={true}
      onClose={onClose}
      open={isOpen}
    >
      <StyledModal.Header>
        <HeaderContainer>
          {t('ManageTags')}
          <Subtitle>
            {t('AddEditRemove', { deviceTags: devices?.length })}
          </Subtitle>
        </HeaderContainer>
      </StyledModal.Header>
      <StyledModal.Content>
        <Tags
          setIsEditing={setIsEditing}
          isEditTagValidationShowed={isEditTagValidationShowed}
          setIsEditTagValidationShowed={setIsEditTagValidationShowed}
        />
      </StyledModal.Content>
      <StyledModal.Footer>
        { isEditing &&
          <Button
            content='Apply'
            onClick={() => {
              updateTags(selectedTags)
              setIsEditing(false)
            }}
            type='primary'
            disabled={isEditTagValidationShowed}
          />
        }
      </StyledModal.Footer>
    </StyledModal>
  )
}

ManageTagsModal.defaultProps = {
  t: label => label
}

ManageTagsModal.propTypes = {
  t: PropTypes.func,
  devices: PropTypes.arrayOf()
}

export default ManageTagsModal
