import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { TaggingActionCreators } from 'Store/tagging'
import { noSpacingNoSpecialCharactersExceptUnderscore } from 'Forms/Validators'
import {
  Icon,
  Button
} from '@scuf/common'
import {
  MenuContainer,
  MenuTitle,
  ControlsContainer,
  ControlsRow,
  RowNumber,
  EditNameTagContainer
} from './edit-tags.styles'
import {
  TagsContainer
} from '../../tags.styles'

import Select from '../select'
import { ButtonContainer } from 'Components/ScheduleModal/schedule-modal.styles'

const EditTags = ({
  onCloseEdit,
  selectedTags,
  getValues,
  updateValue,
  isAssetDetail,
  updateTags,
  siteId,
  permissions,
  isEditTagValidationShowed,
  setIsEditTagValidationShowed,
  t,
  ...rest
}) => {
  useEffect(() => {
    getValues()
  }, [getValues])
  const dispatch = useDispatch()
  const sendTagsUpdate = (tags) => {
    dispatch(TaggingActionCreators.updateTagsRequest(
      tags.map(a => ({ assetId: a.assets[0], key: a.key, newValue: a.newValue, value: a.value, siteId }))))
  }
  let hasCreateTaggingPermissions = true

  if(permissions['tagging:create']){
    hasCreateTaggingPermissions = true
  }
  else{
    hasCreateTaggingPermissions = false
  }

  return (
    <Fragment>
      <MenuContainer>
        <MenuTitle>
		{t('EditTags')}
        </MenuTitle>
        <Icon
          color='#FFFFFF'
          name='close'
          onClick={onCloseEdit}
          size='small'
        />
      </MenuContainer>
      <TagsContainer>
        <ControlsContainer>
          {selectedTags.map((tag, i) =>
            <ControlsRow key={tag.id}>
              <RowNumber>{i + 1}</RowNumber>
              <EditNameTagContainer>
                <Select
                  disabled={true}
                  label={i ? '\u00A0' : t('Name')}
                  options={[]}
                  placeholder={tag.key}
                />
              </EditNameTagContainer>
                <Select
                  defaultValue={tag.value}
                  label={i ? '\u00A0' : t('Value')}
                  error={isEditTagValidationShowed ? t('ValidationTagMsg') : ''}
                  onChange={value => {
                    updateValue(tag.id, value)
                    if(noSpacingNoSpecialCharactersExceptUnderscore(value)) {
                      setIsEditTagValidationShowed(true)
                    } else {
                      setIsEditTagValidationShowed(false)
                    }
                  }}
                  options={tag.values || []}
                  search={hasCreateTaggingPermissions}
                />
            </ControlsRow>
          )}
        </ControlsContainer>
      </TagsContainer>
      <ButtonContainer style={{ paddingTop: '.5rem' }}>
        {isAssetDetail &&
          <Button
            content={t('Apply')}
            onClick={() => sendTagsUpdate(selectedTags)} />
        }
      </ButtonContainer>
    </Fragment>
  )
}

EditTags.defaultProps = {
  selectedTags: [],
  t: label => label
}

EditTags.propTypes = {
  selectedTags: PropTypes.array,
  isEditTagValidationShowed: PropTypes.bool,
  setIsEditTagValidationShowed: PropTypes.bool,
  t: PropTypes.func
}

export default EditTags
