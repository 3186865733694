export const autoRenewPaths = [
    '/assets/updates',
    '/assets/management/all',
    '/admin/user_management',
    '/dashboard/sites',
    '/siteRanking',
    '/siteComparison'
    ];

export const autoRenewModals = [
    'uploadAssetModal',
    'scheduleModal',
    'AddAssetsModal',
    'deviceOnBoardSimple',
    'uploadCsv',
    'userFormEdit',
    'userForm',
    'dashboardSitesFilter',
    'settingsModal',
    'dashboardPreferences',
    'ossTerms'
    ];
