import React, { useEffect } from 'react'

import { START_RV, DEVCOMMAND_RVSTART, CHILD_CLOSED, GET_ACCESS_TOKEN, REMOTE_ACCESS_DENIED } from './session.constants'
import { DeviceApi } from '../../Services'
import { ErrorToast } from '../../Themes/ScufStyledComponents'
import { toast } from 'react-toastify'

const Session = ({
  children,
  accessToken
}) => {
  function sendCommands (e) {
    if (e.origin === process.env.REACT_APP_SITE_SAFE) {
        const deviceId = e.data ? e.data.deviceId : (e.message ? e.message.deviceId : '')
        if ((e.data && e.data.type === START_RV) || (e.message && e.message.type === START_RV)) {
          DeviceApi.startRACommand(deviceId, DEVCOMMAND_RVSTART)
          // deviceSendRACommand(deviceId, DEVCOMMAND_RVSTART)
        } else if ((e.data && e.data.type === CHILD_CLOSED) || (e.message && e.message.type === CHILD_CLOSED)) {
          if (window.childWindows && window.childWindows.length > 0) {
            const index = window.childWindows.findIndex(ele => ele.name === deviceId)
            window.childWindows.splice(index, 1)
          }
          if (e.data.triggerRVStop) DeviceApi.stopRACommand(deviceId)
          // deviceSendRACommand(deviceId, DEVCOMMAND_RVSTOP)
        }
      }
  }

  function sendAccessToken (e) {
    if(e.origin === process.env.REACT_APP_SITE_SAFE){
      const { childWindows } = window
      const { type, deviceId } = e.data
      if (type === GET_ACCESS_TOKEN) {
        const index = childWindows && childWindows.findIndex(ele => ele.name === deviceId)
        if (index >= 0) {
          childWindows[index].windowObj.postMessage({ accessToken, deviceId }, '*')
          childWindows[index].loadingStatus = true
        }
      }
    }
  }

  function unauthorisedAccess (e) {
    if(e.origin === process.env.REACT_APP_SITE_SAFE){
      const { type, deviceId } = e.data
      if (type === REMOTE_ACCESS_DENIED) {
        toast(<ErrorToast message={`You are not authorized to Remote Access ${deviceId}`} />)
      }
    }
  }

  function closeAllRemoteAccessWindows (ev) {
    if (window.childWindows) {
      for (const { windowObj } of window.childWindows) windowObj.close()
      delete window.childWindows
    }
  }

  useEffect(() => {
    window.addEventListener('message', sendCommands)
    window.addEventListener('message', unauthorisedAccess)
    window.addEventListener('beforeunload', closeAllRemoteAccessWindows)
    return () => {
      window.removeEventListener('message', sendCommands)
      window.removeEventListener('message', unauthorisedAccess)
      window.removeEventListener('beforeunload', closeAllRemoteAccessWindows)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('message', sendAccessToken)

    return () => {
      window.removeEventListener('message', sendAccessToken)
    }
  }, [accessToken])

  return (
    children
  )
}

export default Session