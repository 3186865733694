import { Button, InputLabel, Modal, Search } from '@scuf/common'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import React, { Component, Fragment } from 'react'
import { Field, FieldArray, Form, FormSection } from 'redux-form'
import styled from 'styled-components/macro'

import ErrorEvent from 'Components/ErrorEvent'
import FormInput from 'Components/FormComponents/FormInput'
import { FormInputContainer, FormRow, Title } from 'Themes/ScufStyledComponents'

import { renderOrganizationRolesT as renderOrganizationRoles } from '../FormSections'
import { isEmail, isLettersOnly, isMax25, isMax64, isRequired, isMax50, noSpaceAllowed, noSpaceAllowedAtEnd, noSpecialCharactersForNames, trimStartName} from '../Validators'

import { bulkCreateEmployees } from '../../features/user-management/apis'
import { ErrorToast, SuccessToast } from '../../Themes/ScufStyledComponents'
import { MODAL_TOAST_CONTAINER } from 'Utils/constants'
import { toast } from 'react-toastify'
import { SitesManagerApi } from '../../Services';

export const FormButtons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 1rem;
  justify-content: flex-end;
`

export class BaseUserForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      disabled: true,
      results: props.users
    }
    this._changeResults = this._changeResults.bind(this)
    this.submitByIdForm = this.submitByIdForm.bind(this)
  }

  componentDidMount () {
    this.props.getRoles()
    this.props.getRootSites()
    this.props.getSiteHierarchy()
    if (isEmpty(this.props.registrationRole)) {
      this.props.getRegistrationRole()
    }
    if (isEmpty(this.props.users) && this.props.edit) {
      this.props.getUsers()
    } else if (!this.props.edit) {
      this.props.setCurrentUser('')
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const enabled = (this.props.valid && !this.props.submitting) && this.props.anyTouched || !this.state.disabled
    const wasEnabled = (prevProps.valid && !prevProps.submitting && prevProps.anyTouched) || !prevState.disabled

    if (enabled !== wasEnabled) {
      this.setState({ disabled: !enabled })
    }
  }

  _changeResults (term) {
    const filteredResults = this.props.users.filter(item => get(item, 'title', '').toLowerCase().includes((term || '').toLowerCase()))
    this.setState({ results: filteredResults })
  }

  async submitByIdForm(byIdData) {
    try {
      const response =  await bulkCreateEmployees({
        employees: byIdData,
        dryRun: false
      })
  if (response.ok && response.data?.data) {
    toast(<SuccessToast message={this.props.t('UserForm_Success')} />, { containerId: MODAL_TOAST_CONTAINER })
    this.props.getUsers()
  } else {
    if (response.status === 409 || response.status === 424 || response.status === 400) {
         const errorDetail = response.data?.data[0]?.errors[0]?.detail
      if (errorDetail) {
        toast(<ErrorToast message={errorDetail} />, { containerId: MODAL_TOAST_CONTAINER })
      } else {
        toast(<ErrorToast message={this.props.t('UserForm_Exists')} />, { containerId: MODAL_TOAST_CONTAINER })
      }

    }
    if(response.status === 403) {
      toast(<ErrorToast message={this.props.t('UserForm_AccessDenied')} />, { containerId: MODAL_TOAST_CONTAINER })
    }
  }
    } catch (e) {
      console.error(e)
      toast(<ErrorToast message={this.props.t('UserForm_Failed')} />)
    } finally {
      this.props.reset()
    }
  }

  render () {
    const { disabled, results } = this.state
    const { handleSubmit, reset, rootSites, uiRoles, initialValues,
      formData, t, closeModal, createUser, updateUser, loading, edit,
      setCurrentUser, isSSOForm, uiRolesWithDescription, currentUserRoles, isGlobalUser, isById } = this.props
    const isNew = !initialValues.id
    const isByIdCheck = (isById || !!initialValues.personIdentifier?.employeeId) ? false : true
    const hasEmail = isByIdCheck || !!initialValues.personIdentifier?.loginEmailAddress
    const editById = !!initialValues.personIdentifier?.employeeId
    return (
      <Form
        id='userForm'
        autoComplete='off'
        onSubmit={ handleSubmit(async (data) => {
          if (loading || (edit && isNew)) {
            return null
          }
          if (isNew && !isById && !editById) {
            const newMappedData = Object.assign({}, data)
            const getSiteRoots = await SitesManagerApi.getRootSites()
              const getSiteRootsData = getSiteRoots.data
              let orgId = getSiteRootsData[0]?.organizationalUnitIdentifier?.organizationalUnitGuid
              if(!orgId) {  orgId = ''}
            const orgUnitsData = data?.organization?.organizationalUnits
            const orgUnits = orgUnitsData?.map(org => ({
              ...org,
              parent: org['id'] ? org['id'] : orgId
            }))
			     newMappedData['organization']['organizationalUnits'] = orgUnits
            createUser(newMappedData)
          } else if(isById){
            const getSiteRoots = await SitesManagerApi.getRootSites();
            const getSiteRootsData = getSiteRoots.data;
            let orgId = getSiteRootsData[0]?.organizationalUnitIdentifier?.organizationalUnitGuid;
            if(!orgId) {  orgId = ''}

            const orgUnits = data?.organization?.organizationalUnits;
            const byIdData= [ orgUnits?.map(unit => ({
              "organizationId": orgId,
              "firstName": data?.personDetails?.firstName,
              "middleName": data?.personDetails?.middleName,
              "lastName": data?.personDetails?.lastName,
              "employeeId": data?.personIdentifier?.employeeId,
              "siteId": unit?.id,
              "id" : data?.id
              }))
            ]

            this.submitByIdForm(byIdData[0])
          } else {
            updateUser(data)
          }
          reset()
        })}
      >
        <Modal.Header>
          <div style={{ width: '100%' }}>
            <FormRow justifyContent='space-between'>
              { hasEmail && <Title>{isNew ? t('UserForm_New') : t('UserForm_Edit')}</Title> }
              { isById && <b style={{ fontSize: '0.875rem' }}>{ t('UserForm_NewById') }</b>}
              { editById && <Title>{ t('UserForm_Edit')}</Title> }
              {edit
                ? <Search
                  results={results}
                  placeholder={t('UserForm_MissingSelection')}
                  onSearchChange={this._changeResults}
                  onResultSelect={(user) => setCurrentUser(user.id)}
                />
                : null
              }
            </FormRow>
          </div>
        </Modal.Header>
        {loading || (edit && isNew)
          ? <ErrorEvent loading={loading}>{t('UserForm_MissingSelection')}</ErrorEvent>
          : <Fragment>
            { (hasEmail || isById || editById) && <FormSection name='personDetails'>
              <FormRow>
                <FormInputContainer>
                  <InputLabel
                    label={t('UserForm_FirstName')}
                    htmlFor='firstName'
                  />
                  <Field
                    placeholder={t('UserForm_FirstName')}
                    name='firstName'
                    id='firstName'
                    component={FormInput}
                    validate={[isRequired, noSpecialCharactersForNames, isMax25, noSpaceAllowedAtEnd]}
                    normalize={trimStartName}
                  />
                </FormInputContainer>
                <FormInputContainer>
                  <InputLabel
                    indicator='optional'
                    label={t('UserForm_MiddleName')}
                    htmlFor='middleName'
                  />
                  <Field
                    placeholder={t('UserForm_MiddleName')}
                    name='middleName'
                    id='middleName'
                    component={FormInput}
                    validate={[noSpecialCharactersForNames, isMax25, noSpaceAllowedAtEnd]}
                    normalize={trimStartName}
                  />
                </FormInputContainer>
                <FormInputContainer>
                  <InputLabel
                    label={t('UserForm_LastName')}
                    htmlFor='lastName'
                  />
                  <Field
                    placeholder={t('UserForm_LastName')}
                    name='lastName'
                    id='lastName'
                    component={FormInput}
                    validate={[isRequired, noSpecialCharactersForNames, isMax25, noSpaceAllowedAtEnd]}
                    normalize={trimStartName}
                  />
                </FormInputContainer>
              </FormRow>
            </FormSection> }
            <FormRow>
            { hasEmail && <FormSection name='personIdentifier'>
                {hasEmail ? (
                  <FormInputContainer flex={1.2}>
                    <InputLabel
                      label={t('UserForm_Email')}
                      htmlFor='username'
                    />
                    <Field
                      disabled={!!initialValues.id}
                      placeholder={t('UserForm_Email')}
                      name='loginEmailAddress'
                      id='loginEmailAddress'
                      component={FormInput}
                      validate={[isRequired, isEmail, isMax64]}
                    />
                  </FormInputContainer>
                ) : (
                  <FormInputContainer flex={1.2}>
                    <InputLabel
                      label={t('UserForm_EmployeeId')}
                      htmlFor='EmployeeId'
                    />
                    <Field
                      disabled={!!initialValues.id}
                      placeholder={t('UserForm_EmployeeId')}
                      name='employeeId'
                      id='employeeId'
                      component={FormInput}
                      validate={[isRequired, noSpaceAllowed, isMax50]}
                    />
                  </FormInputContainer>
                )}
              </FormSection> }
              { (isById || editById) && <FormSection name='personIdentifier'>
                  <FormInputContainer flex={1.2}>
                    <InputLabel
                      label={t('UserForm_EmployeeId')}
                      htmlFor='EmployeeId'
                    />
                    <Field
                      disabled={!!initialValues.id}
                      placeholder={t('UserForm_EmployeeId')}
                      name='employeeId'
                      id='employeeId'
                      component={FormInput}
                      validate={[isRequired, noSpaceAllowed, isMax50]}
                    />
                  </FormInputContainer>
              </FormSection> }
            </FormRow>
            { (hasEmail || isById || editById) &&  <FormSection name='organization'>
              <FieldArray
                props={{
                  sites: rootSites,
                  orgs: [],
                  uiRoles,
                  formData,
                  forceEnable: () => this.setState({ disabled: false }),
                  uiRolesWithDescription,
                  hasEmail,
                  isById,
                  editById,
                  initialValues,
                  currentUserRoles,
                  isGlobalUser
                }}
                name='organizationalUnits'
                component={renderOrganizationRoles}
              />
            </FormSection> }
            <FormButtons>
              <Button
                type='secondary'
                actionType='button'
                content={t('UserForm_Cancel')}
                onClick={closeModal}
              />
              <Button
                type='primary'
                actionType='submit'
                content={t('UserForm_Submit')}
                disabled={disabled}
              />
            </FormButtons>
          </Fragment>
        }
      </Form>
    )
  }
}

BaseUserForm.propTypes = {
  roles: PropTypes.array,
  rootSites: PropTypes.object,
  t: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  reset: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  header: PropTypes.node,
  closeModal: PropTypes.func,
  updateUser: PropTypes.func,
  createUser: PropTypes.func,
  getRootSites: PropTypes.func,
  getRoles: PropTypes.func,
  loading: PropTypes.bool,
  isSSOForm: PropTypes.bool
}

BaseUserForm.defaultProps = {
  uiRoles: [],
  rootSites: {},
  t: () => null,
  getRootSites: () => null,
  getRoles: () => null,
  handleSubmit: () => null
}

export default BaseUserForm
