export const isSelectRequired = value => !value || value.length < 1 ? 'FormInput_RequiredField' : ''

export const isRequired = value => !value ? 'FormInput_RequiredField' : ''

export const maxLength = (limit, field) => text => (text && text.length > limit ? `FormInput_LimitField_${field}` : '')

export const isMaxCharacters = (maxCharcters, fieldName = 'Field') => value => !value || value.length <= maxCharcters ? '' : `FormInput_NotExceed,${maxCharcters},FormInput_Characters`

export const numberLength = (maxLength) => value => !value || value.length <= maxLength ? '' : `FormInput_NotExceed,${maxLength},FormInput_digits`

export const maxValue = max => value =>
  value && value > max ? `FormInput_MaxFirst, ${max} ,FormInput_MaxSecond` : ''

export const minValue = min => value =>
  value && value < min ? `FormInput_Min,` + min : ''

export const isEmail = value => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(value) || !value ? '' : 'FormInput_InvalidEmail'
}

export const isNumbersOnly = value => {
  return !isNaN(value) || !value ? '' : 'FormInput_OnlyNumbers'
}

export const isLettersOnly = value => {
  const regex = /^[A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF}'-\s]*$/
  return regex.test(value) || !value ? '' : 'FormInput_OnlyLetters'
}

export const noSpecialCharacters = value => {
  const regex = /^[^$%&?+=:;`~{})(@!|<>#]*$/
  // NOTE: Allowing only a few special characters, that are common on addresses, but not XSS related
  return regex.test(value) || !value ? '' : 'FormInput_SpecialCharactersNotAllowed'
}

export const noSpecialCharactersForNames = value => {
  const regex =  /^[^\\/0-9^$%+=÷±&¿?*:,;`´'~¬{}[()@!¡|<>#.‰•‡–—¯¹²³‹›«˜™°"_¢£¤¥¦§¶¨©®ªµ·¸½¾]+$/
  return regex.test(value) || !value ? '' : 'FormInput_OnlyLetters'
}

export const noSymbols = value => {
  const regex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s\w\d]*$/
  return regex.test(value) || !value ? '' : 'FormInput_SpecialCharactersNotAllowed'
}

export const noSpecialCharactersExceptParentheses = value => {
  const regex = /^[a-zA-záéíóúÁÉÍÓÚñÑ\s\w\d*\-,.#/(/)]*$/
  // NOTE: Allowing only a few special characters, that are common on addresses, but not XSS related
  return regex.test(value) || !value ? '' : 'FormInput_SpecialCharactersNotExceptPar'
}

export const noSpacingNoSpecialCharactersExceptHyphen = value => {
  const regex = /[^\p{L}\p{Nd}-]+/u
  return regex.test(value) || !value ? true : false
}

export const noSpacingNoSpecialCharactersExceptUnderscore = value => {
  const regex = /[^\p{L}\p{Nd}_]+/u
  return regex.test(value) || !value ? true : false
}

export const dateCharacters = value => {
  const regex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s\w\d\-/]*$/
  return regex.test(value) || !value ? '' : 'FormInput_SpecialCharactersNotAllowed'
}

export const isTelephone = value => {
  const regex = /^[+*#\d\s]*$/
  return regex.test(value) || !value ? '' : 'FormInput_ValidPhoneNum'
}

export const isInteger = value => {
  const regex = /^[-+]?\d*$/
  return regex.test(value) || !value ? '' : 'FormInput_NoDecimals'
}

export const isPostalCode = value => {
  const regex = /^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/
  return regex.test(value) || !value ? '' : 'FormInput_InvalidPostalCode'
}

export const isTwoDecimalPlaces = value => {
  const regex = /^\d+(\.\d{1,2})?$/
  return regex.test(value) || !value ? '' : 'FormInput_TwoDecimalPlaces'
}

export const noSpecialCharactersWithoutSpaces = value => {
  const regex = /^[a-zA-z\w\d]*$/
  return regex.test(value) || !value ? '' : 'FormInput_SpecialCharactersNotAllowed'
}

export const noSpaceAllowed = value => {
  const regex = /^\S*$/
  return regex.test(value) || !value ? '' : 'FormInput_SpacesNotAllowed'
}

export const noSpaceAllowedAtEnd = value => {
  const regex = /^(?!\s).+(?<!\s)$/
  return regex.test(value) || !value ? '' : 'FormInput_SpacesNotAllowedAtEnd'
}

export const isHexadecimal = value => /[0-9a-fA-F]+/.test(value) ? '' : 'FormInput_Hexadecimal'

export const isUnique = (ids) => (name) => ids.indexOf(name) !== -1 ? 'FormInput_uniqueField' : ''

export const trimStartName = (value) => value?.trimStart()

export const isMax6 = isMaxCharacters(6)
export const isMax18 = isMaxCharacters(18)
export const isMax25 = isMaxCharacters(25)
export const isMax30 = isMaxCharacters(30)
export const isMax50 = isMaxCharacters(50)
export const isMax64 = isMaxCharacters(64)
export const isMax128 = isMaxCharacters(128)
export const isMax254 = isMaxCharacters(254)
export const minValue1 = minValue(1)
export const maxValue100 = maxValue(100)
export const maxValue50 = maxValue(50)
export const maxValue31 = maxValue(31)
