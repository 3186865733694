export const formatBytes = (bytes, decimals = 2, size = false) => {
  if (bytes === '0' || bytes === 0) return '0 KB';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return size === true ? sizes[i] : parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const niceBytes = (unit, x) => {
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const index = units.findIndex(u => u === unit);
  var i, valueFormated = x
  if (unit === 'Bytes') return x
  for (i = 1; i <= index; i++) {
    valueFormated = valueFormated / 1024
  }
  return valueFormated
}